import { Button, ButtonType } from "../../../styles/Button.styled";
import { HBox, VBox } from "../../../styles/Flexbox.styled";
import { follow, unfollow } from "../../../services/FollowerService";
import { useEffect, useState } from "react";

import { AxiosResponse } from "axios";
import { FarmerProps } from "./IFarmer";
import Formatter from "../../../utils/Formatter";
import HalfModal from "../../HalfModal/HalfModal";
import HighligtedText from "../../HighlightedText/HighlightedText";
import { IFarmer } from "../../../services/IFollowService";
import ListItem from "../../ListItem/ListItem";
import Text from "../../Text/Text";
import TextSizeEnum from "../../../models/enums/TextSizeEnum";
import TextTypeEnum from "../../../models/enums/TextTypeEnum";
import TextWeightEnum from "../../../models/enums/TextWeightEnum";
import { UserType } from "../../../models/enums/UserType";
import { formatDate } from "../../../utils/VariableHelper";
import { getText } from "../../../locales/initI18n";
import { useQueryClient } from "@tanstack/react-query";
import useStatistics from "../../../hooks/useStatistics";
import { useTheme } from "styled-components";

const Farmer = ({
  isVisible,
  farmer,
  onClose,
  onConfirm,
  flight,
}: FarmerProps) => {
  const theme = useTheme();
  const queryClient = useQueryClient();
  const [farmerState, setFarmerState] = useState<IFarmer | undefined>();
  const { isAdmin, user, userFlights, userPigeons } = useStatistics(farmer?.id);

  useEffect(() => {
    setFarmerState(farmer as unknown as IFarmer);
  }, [farmer, farmer?.id, isVisible]);

  if (!farmerState || !isVisible) return null;

  const changeFollow = () => {
    if (!farmerState) return;
    if (farmerState.isFollowed) void unfollow(farmerState.id);
    else void follow(farmerState.id);
    setFarmerState((old) => {
      if (!old) return old;
      return { ...old, isFollowed: !old.isFollowed };
    });
    queryClient.setQueryData(
      ["farmers", ""],
      (oldData: AxiosResponse<IFarmer[], any> | undefined) => {
        if (!oldData) return oldData;
        const newData = oldData.data.map((f: any) => {
          if (f.username === farmerState.username) {
            if (f.isFollowed && f.isFollowing) {
              return {
                ...f,
                isFollowed: !f.isFollowed,
                isFollowing: !f.isFollowing,
              };
            }
            return { ...f, isFollowed: !f.isFollowed };
          }
          return f;
        });
        return { ...oldData, data: newData };
      }
    );
  };
  const getButtonText = () => {
    if (farmerState.isFollowed && farmerState.isFollowing)
      return "Przestań obserwować";
    if (farmerState.isFollowed) return "Anuluj zaproszenie";
    if (farmerState.isFollowing) return "Przyjmij zaproszenie";
    return "Zaproś do znajomych";
  };
  return (
    <HalfModal
      onClose={onClose}
      isExpander={false}
      heightDivider={1.1}
      visible={isVisible}
      Padding="0"
    >
      <VBox Margin="40px 20px 0 20px" gap="5px">
        <HBox gap="5px" visible={!flight}>
          <HighligtedText
            visible={farmerState.isFollowed && farmerState.isFollowing}
            backgroundColor={theme.palette.Green60}
            text="Znajomy"
          />
          <HighligtedText
            backgroundColor={theme.palette.Yellow100}
            visible={farmerState.type === UserType.Planner}
            text="Lotowy"
          />
          <HighligtedText
            visible={farmerState.isFollowed && !farmerState.isFollowing}
            backgroundColor={theme.palette.Primary60}
            text="Wysłałeś zaproszenie"
          />
          <HighligtedText
            visible={!farmerState.isFollowed && farmerState.isFollowing}
            backgroundColor={theme.palette.Green60}
            text="Zostałeś zaproszony"
          />
        </HBox>
        <Text type="h4" size="large" weight="bold">
          {getText("General.Breeder")}
        </Text>
      </VBox>
      <ListItem title="Nazwa użytkownika" description={farmerState.username} />
      <ListItem
        title="Okręg"
        description={farmerState.district ? farmerState.district.name : "Brak"}
        darkest={true}
      />
      <ListItem
        title="Oddział"
        description={
          farmerState.branch
            ? `${farmerState.branch.branch_number}, ${farmerState.branch.name}`
            : "Brak"
        }
        darkest={false}
      />
      <ListItem
        title="Sekcja"
        description={farmerState.section ? farmerState.section.name : "Brak"}
        darkest={true}
      />
      <ListItem
        visible={!!flight}
        title="Czas przybycia"
        description={
          flight?.endDate
            ? formatDate(flight?.endDate)
            : "Brak wybranego gołębnika"
        }
      />
      <ListItem
        visible={!!flight}
        title="Miejsce Wylotu"
        description={`${flight?.start.name} (${flight?.start.latitude}, ${flight?.start.longitude})`}
        darkest
      />
      <ListItem
        visible={!!flight}
        title="Gołębnik"
        description={
          !!flight?.end
            ? `${flight?.end?.name} (${flight?.end?.latitude}, ${flight?.end?.longitude})`
            : "Brak wybranego gołębnika"
        }
      />
      <VBox Margin="0 10px" gap="5px" visible={isAdmin}>
        <Text
          Margin="20px 0"
          type={TextTypeEnum.h4}
          size={TextSizeEnum.large}
          weight={TextWeightEnum.medium}
        >
          Pozostałe
        </Text>
        <ListItem
          overflowX="hidden"
          overflowY="hidden"
          borderRadius="10px"
          darkest
          title="Imie"
          description={user.firstName}
        />
        <ListItem
          overflowX="hidden"
          overflowY="hidden"
          borderRadius="10px"
          darkest
          title="Nazwisko"
          description={user.lastName}
        />
        <ListItem
          overflowX="hidden"
          overflowY="hidden"
          borderRadius="10px"
          darkest
          title="Numer telefonu"
          description={user.phoneNumber}
        />
        <ListItem
          overflowX="hidden"
          overflowY="hidden"
          borderRadius="10px"
          darkest
          title="Email"
          description={farmerState.email}
        />
        <ListItem
          overflowX="hidden"
          overflowY="hidden"
          borderRadius="10px"
          darkest
          title="Data stworzenia konta"
          description={Formatter.DateTime(user.createDate)}
        />
        <ListItem
          overflowX="hidden"
          overflowY="hidden"
          borderRadius="10px"
          darkest
          title="Ostatnie logowanie"
          description={Formatter.DateTime(user.lastLoginDate)}
        />
        <ListItem
          overflowX="hidden"
          overflowY="hidden"
          borderRadius="10px"
          darkest
          title="Ostatnia próba logowanie"
          description={Formatter.DateTime(user.lastTryLoginDate)}
        />
        <Text
          Margin="20px 0"
          type={TextTypeEnum.h4}
          size={TextSizeEnum.large}
          weight={TextWeightEnum.medium}
        >
          Statystyki
        </Text>
        <ListItem
          overflowX="hidden"
          overflowY="hidden"
          borderRadius="10px"
          darkest
          title="Łącznie lotów"
          description={userFlights.total}
        />
        <ListItem
          overflowX="hidden"
          overflowY="hidden"
          borderRadius="10px"
          darkest
          title="Dzisiejsza aktywność (loty)"
          description={userFlights.todayActivity}
        />
        <ListItem
          overflowX="hidden"
          overflowY="hidden"
          borderRadius="10px"
          darkest
          title="Dwudniowa aktywność (loty)"
          description={userFlights.twoDaysActivity}
        />
        <ListItem
          overflowX="hidden"
          overflowY="hidden"
          borderRadius="10px"
          darkest
          title="Łącznie gołębie"
          description={userPigeons.total}
        />
        <ListItem
          overflowX="hidden"
          overflowY="hidden"
          borderRadius="10px"
          darkest
          title="Dzisiejsza aktywność (gołębie)"
          description={userPigeons.todayActivity}
        />
        <ListItem
          overflowX="hidden"
          overflowY="hidden"
          borderRadius="10px"
          darkest
          title="Dwudniowa aktywność (gołębie)"
          description={userPigeons.twoDaysActivity}
        />
        <ListItem
          overflowX="hidden"
          overflowY="hidden"
          borderRadius="10px"
          darkest
          title="Łącznie gołębników"
          description={user.totalCotes}
        />
        <ListItem
          overflowX="hidden"
          overflowY="hidden"
          borderRadius="10px"
          darkest
          title="Łącznie miejsc wypuszczeń"
          description={user.totalRelease}
        />
        <ListItem
          overflowX="hidden"
          overflowY="hidden"
          borderRadius="10px"
          darkest
          title="Łącznie opinii"
          description={user.totalOpinions}
        />
      </VBox>
      <VBox gap="10px" Margin="20px 20px 0 20px">
        <Button
          visible={!flight}
          buttonType={ButtonType.Success}
          height="56px"
          width="100%"
          onClick={() => changeFollow()}
        >
          {getButtonText()}
        </Button>
      </VBox>
    </HalfModal>
  );
};

export default Farmer;
