import { useQuery, useQueryClient } from "@tanstack/react-query";

import Pigeon from "../models/Pigeon";
import { getPigeon } from "../services/PigeonService";
import { useEffect } from "react";
import { useHookstate } from "@hookstate/core";

const usePigeon = (id?: number | string) => {
  const queryClient = useQueryClient();
  const state = useHookstate({ pigeon: new Pigeon() });
  const getPigeonInternal = async () => {
    const pigeon = await getPigeon(id);
    state.pigeon.set(pigeon);
    return pigeon;
  };
  const query = useQuery(["pigeon", id], getPigeonInternal);

  const pigeon = state.get({ noproxy: true }).pigeon as Pigeon;

  useEffect(() => {
    if (pigeon?.id !== id && query.isFetched && query.data) {
      state.pigeon.set(query.data);
    }
  }, [query.data, pigeon.id, query.isFetched]);

  return { pigeon: pigeon, setter: state.pigeon, refetch: query.refetch };
};

export default usePigeon;
