import { Branch } from "../models/Branch";
import { District } from "../models/District";
import { Section } from "../models/Section";
import { authGet } from "./BaseService";

export const getDistricts = async (rawFilters?: string) => {
  const districts = await authGet<District[]>(`district?filters=${rawFilters}`);
  return districts.data.map(District.create.bind(District)) as District[];
};

export const getBranches = async (districtId?: number) => {
  if (!districtId) return [];
  const branches = await authGet<Branch[]>(`district/${districtId}/branches`);
  return branches.data.map(Branch.create.bind(Branch)) as Branch[];
};

export const getSections = async (districtId?: number, branchId?: number) => {
  if (!districtId || !branchId) return [];
  const sections = await authGet<Section[]>(
    `district/${districtId}/branches/${branchId}/sections`
  );
  return sections.data.map(Section.create.bind(Section)) as Section[];
};
