import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";

import PageOptions from "../models/PageOptions";
import { getPigeonPairs } from "../services/PigeonPairService";

const usePigeonPairs = (options = new PageOptions()) => {
  const queryClient = useQueryClient();

  const queryFlights = useInfiniteQuery({
    queryKey: options.getQueryKey("pigeon-pairs"),
    queryFn: ({ pageParam = 1 }) => getPigeonPairs(options.setPage(pageParam)),
    getNextPageParam: options.nextPagefn,
  });

  const invalidate = () => {
    queryClient.invalidateQueries(["pigeon-pairs"]);
    queryClient.invalidateQueries(["pigeon-pair"]);
  };

  return {
    pigeonPairs: queryFlights.data?.pages.flatMap((page) => page.data) || [],
    lastMeta: queryFlights.data?.pages[queryFlights.data.pages.length - 1].meta,
    busy: queryFlights.isLoading,
    refetch: queryFlights.refetch,
    fetchNextPage: queryFlights.fetchNextPage,
    invalidate,
  };
};

export default usePigeonPairs;
