import { ComponentType, memo, useState } from "react";
import {
  addEgg,
  changeEggStatus,
  deleteEgg,
  hatchEgg,
} from "../../services/EggService";
import withDialogs, { WithDialogsProps } from "../withDialogs/withDialogs";

import AddEgg from "../../components/modals/AddEgg";
import BaseDialogProps from "../../models/BaseDialogProps";
import DialogResult from "../../models/DialogResult";
import Egg from "../../models/Egg";
import HatchEgg from "../../components/modals/HatchEgg";
import { MessageBoxAction } from "@ui5/webcomponents-react";
import PageOptions from "../../models/PageOptions";
import PigeonPair from "../../models/PigeonPair";
import WithEggHandlerProps from "./withEggHandlerProps";
import { getText } from "../../locales/initI18n";
import { setError } from "../../redux/modules/error";
import { useAppDispatch } from "../../redux/hooks";
import useEggs from "../../hooks/useEggs";
import usePigeonPairs from "../../hooks/usePigeonPairs";
import usePigeons from "../../hooks/usePigeons";
import { EggState } from "../../models/enums/EggStateEnum";
import useMessage from "../../hooks/useMessage";

function withEggHandler<P extends WithEggHandlerProps>(
  Component: ComponentType<P>
) {
  const WithComponent = (
    props: Omit<P, keyof WithEggHandlerProps> & WithDialogsProps
  ) => {
    const { openMessage } = useMessage();
    const dispatch = useAppDispatch();
    const { invalidate: invalidatePair } = usePigeonPairs();
    const { invalidate: invalidateEgg } = useEggs();
    const { removeCaches: invalidatePigeon } = usePigeons(new PageOptions());
    const onAddEgg = async (pair: PigeonPair) => {
      if (!pair) return;
      const result = await props.openDialog("AddEgg");
      if (result.isClose()) return;

      const eggs = result.getData<Egg[]>();

      try {
        for (const egg of eggs) {
          await addEgg(pair, egg.dateOfLaying, egg.status, egg.brood);
        }
        invalidateEgg();
        invalidatePair();
      } catch (e) {
        dispatch(setError(e));
        throw e;
      }
      return eggs;
    };
    const onDeleteEgg = async (egg: Egg) => {
      const confirm = await openMessage(
        getText("withEggHandler.ConfirmDeleteEgg")
      );
      if (confirm !== MessageBoxAction.OK) return;

      try {
        await deleteEgg(egg.id);
        invalidateEgg();
      } catch (e) {
        dispatch(setError(e));
        throw e;
      }
      return;
    };
    const onHatchEgg = async (egg: Egg) => {
      const result = await props.openDialog("HatchEgg", { egg });
      if (result.isClose()) return;

      const hatchDate = result.getData<{ hatchDate: Date }>().hatchDate;
      try {
        await hatchEgg(egg, hatchDate);
        invalidateEgg();
        invalidatePigeon();
      } catch (e) {
        dispatch(setError(e));
        throw e;
      }
      return;
    };
    const onEggChangeStatus = async (egg: Egg, status: EggState) => {
      try {
        await changeEggStatus(egg, status);
        invalidateEgg();
      } catch (e) {
        dispatch(setError(e));
        throw e;
      }
    };
    return (
      <Component
        {...(props as unknown as P)}
        onAddEgg={onAddEgg}
        onRemoveEgg={onDeleteEgg}
        onHatchEgg={onHatchEgg}
        onEggChangeStatus={onEggChangeStatus}
      />
    );
  };

  return memo(withDialogs(WithComponent, renderDialogs));
}

const renderDialogs = (
  dialogName: string,
  props: BaseDialogProps,
  onClose: (value: DialogResult) => void
) => {
  switch (dialogName) {
    case "AddEgg":
      return (
        <AddEgg
          {...props}
          isVisible
          onConfirm={(value) =>
            onClose(new DialogResult(MessageBoxAction.OK, value))
          }
          onClose={(value) => onClose(new DialogResult(MessageBoxAction.Close))}
        />
      );
    case "HatchEgg":
      const egg = (props as { egg: Egg }).egg;
      return (
        <HatchEgg
          {...props}
          egg={egg}
          isVisible
          onConfirm={(value) =>
            onClose(new DialogResult(MessageBoxAction.OK, value))
          }
          onClose={(value) => onClose(new DialogResult(MessageBoxAction.Close))}
        />
      );
    default:
      return null;
  }
};

export default withEggHandler;
