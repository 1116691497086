import { authDelete, authGet, authPatch, authPost, post } from "./BaseService";

import Page from "../models/Page";
import PageOptions from "../models/PageOptions";
import Pigeon from "../models/Pigeon";
import PigeonSeasonAssignment from "../models/PigeonSeasonAssignment";
import { SeasonAssignmentGroup } from "../models/enums/SeasonAssignmentGroup";

export const assignToSeason = (
  pigeon: Pigeon,
  season: number,
  group: SeasonAssignmentGroup
) => {
  return authPost(`pigeon-season-assignment`, {
    pigeonId: pigeon.id,
    year: season,
    group: group,
  });
};

export const getAssignments = async (options: PageOptions) => {
  const result = await authGet<object[]>(
    `pigeon-season-assignment?${options.getParams()}`
  );

  const page = new Page<PigeonSeasonAssignment>().fillData(result.data);
  page.data = page.data.map((item) => PigeonSeasonAssignment.create(item));
  return page;
};

export const getUnassignedPigeons = async (
  options: PageOptions,
  year?: number | null
) => {
  if (!year) return new Page<Pigeon>();
  const result = await authGet<object[]>(
    `pigeon-season-assignment/unassigned/${year}?page=${options.page}&take=${options.take}`
  );

  const page = new Page<Pigeon>().fillData(result.data);
  page.data = page.data.map((item) => Pigeon.create(item));
  return page;
};

export const getAssignment = async (id?: number | string) => {
  if (!id) return new PigeonSeasonAssignment();
  const result = await authGet(`pigeon-season-assignment/${id}`);
  return PigeonSeasonAssignment.create(result.data);
};

export const getAssignmentByYear = async (pigeon: Pigeon, year: string) => {
  if (!pigeon || !pigeon.id) return new PigeonSeasonAssignment();
  const result = await authGet(
    `pigeon-season-assignment/season/${pigeon.id}/${year}`
  );
  return PigeonSeasonAssignment.create(result.data);
};

export const setAssignment = async (assignment: PigeonSeasonAssignment) => {
  return post(`pigeon-season-assignment`, assignment);
};

export const updateAssignment = async (
  id: number,
  group: SeasonAssignmentGroup
) => {
  return authPatch(`pigeon-season-assignment/${id}`, { group });
};

export const removeAssignment = (id: number) => {
  return authDelete(`pigeon-season-assignment/${id}`);
};
