import {
  IAlignItems,
  IJustifyContent,
  IPosition,
  IShadowType,
  IWhiteSpace,
} from "./IGlobalStyled";
import { addCalc, createShadow } from "../utils/style";

import { devices } from "./Devices";
import styled from "styled-components";

export type BlockType = {
  flexGrow?: string;
  alignItems?: IAlignItems;
  justifyContent?: IJustifyContent;
  Margin?: string;
  Padding?: string;
  position?: IPosition;
  gap?: string;
  flexWrap?: string;
  width?: string;
  height?: string;
  minWidth?: string;
  minHeight?: string;
  maxWidth?: string;
  maxHeight?: string;
  visible?: boolean;
  backgroundColor?: string;
  flexBasis?: string;
  overflowX?: string;
  overflowY?: string;
  zIndex?: number;
  Top?: string;
  Left?: string;
  Right?: string;
  Bottom?: string;
  BorderRadius?: string;
  shadow?: IShadowType;
  border?: string;
  whiteSpace?: IWhiteSpace;
  disabled?: boolean;
  display?: string;

  RScalePaddingSM?: number;
  RScaleMarginSM?: number;
  RIsShadowSM?: boolean;
  RMinWidthSM?: string;
  RInvertedDirSM?: boolean;
};

export const FlexBox = styled.section<BlockType>`
  flex-grow: ${({ flexGrow }) => flexGrow || 0};
  align-items: ${({ alignItems }) => alignItems || "stretch"};
  justify-content: ${({ justifyContent }) => justifyContent || "flex-start"};
  margin: ${({ Margin }) => Margin || 0};
  padding: ${({ Padding }) => Padding || 0};
  position: ${({ position }) => position || "static"};
  flex-wrap: ${({ flexWrap }) => flexWrap || "nowrap"};
  gap: ${({ gap }) => gap || 0};
  width: ${({ width }) => width || "auto"};
  height: ${({ height }) => height || "auto"};
  min-width: ${({ minWidth }) => minWidth || "auto"};
  min-height: ${({ minHeight }) => minHeight || "auto"};
  max-width: ${({ maxWidth }) => maxWidth || "auto"};
  max-height: ${({ maxHeight }) => maxHeight || "auto"};
  display: ${({ visible = true, display }) =>
    display ? display : visible ? "flex" : "none"};
  background-color: ${({ backgroundColor }) =>
    backgroundColor || "transparent"};
  ${({ flexBasis }) => (flexBasis ? `flex-basis: ${flexBasis}` : "")};
  overflow-x: ${({ overflowX }) => overflowX || "visible"};
  overflow-y: ${({ overflowY }) => overflowY || "visible"};
  z-index: ${({ zIndex }) => zIndex || 0};
  top: ${({ Top }) => Top || "auto"};
  left: ${({ Left }) => Left || "auto"};
  right: ${({ Right }) => Right || "auto"};
  bottom: ${({ Bottom }) => Bottom || "auto"};
  border-radius: ${({ BorderRadius }) => BorderRadius || "0"};
  ${({ shadow }) => (shadow ? `box-shadow: ${createShadow(shadow)}` : "")};
  border: ${({ border }) => border || "none"};
  white-space: ${({ whiteSpace }) => whiteSpace || "normal"};
  pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
`;

export const HBox = styled(FlexBox)`
  flex-direction: row;
`;

export const VBox = styled(FlexBox)`
  flex-direction: column;
`;

/**
 * @description Responsive FlexBox Component
 * @param {number} RScalePaddingSM - Scale Padding on Small Screen (R = Responsive)
 * @param {number} RScaleMarginSM - Scale Margin on Small Screen
 * @param {boolean} RIsShadowSM - Is Shadow on Small Screen
 *
 */
export const ResponsiveVBox = styled(VBox)`
  @media ${devices.sm} {
    padding: ${({ RScalePaddingSM = 2, Padding }) =>
      addCalc(Padding, RScalePaddingSM)};
    margin: ${({ RScaleMarginSM = 2, Margin }) =>
      addCalc(Margin, RScaleMarginSM)};
    ${({ shadow }) => (shadow ? `box-shadow: ${createShadow(shadow)}` : "")};
    min-width: ${({ RMinWidthSM, minWidth }) => RMinWidthSM || minWidth};
    flex-direction: column;
  }
  ${({ RIsShadowSM, shadow }) =>
    RIsShadowSM ? `box-shadow: ${createShadow(shadow)}` : "box-shadow: none"};
  flex-direction: ${({ RInvertedDirSM }) =>
    RInvertedDirSM ? "row" : "column"};
`;

/**
 * @description Responsive FlexBox Component
 * @param {number} RScalePaddingSM - Scale Padding on Small Screen (R = Responsive)
 * @param {number} RScaleMarginSM - Scale Margin on Small Screen
 * @param {boolean} RIsShadowSM - Is Shadow on Small Screen
 *
 */
export const ResponsiveHBox = styled(HBox)`
  @media ${devices.sm} {
    padding: ${({ RScalePaddingSM = 2, Padding }) =>
      addCalc(Padding, RScalePaddingSM)};
    margin: ${({ RScaleMarginSM = 2, Margin }) =>
      addCalc(Margin, RScaleMarginSM)};
    ${({ shadow }) => (shadow ? `box-shadow: ${createShadow(shadow)}` : "")};
    min-width: ${({ RMinWidthSM, minWidth }) => RMinWidthSM || minWidth};
    flex-direction: row;
  }
  ${({ RIsShadowSM, shadow }) =>
    RIsShadowSM ? `box-shadow: ${createShadow(shadow)}` : "box-shadow: none"};
  flex-direction: ${({ RInvertedDirSM }) =>
    RInvertedDirSM ? "column" : "row"};
`;
