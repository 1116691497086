import { HBox, VBox } from "../../styles/Flexbox.styled";
import { ListItemAction, ListItemProps } from "./IListItem";
import { useEffect, useState } from "react";

import { ButtonOpacity } from "../../styles/ButtonOpacity.styled";
import Checkbox from "../Checkbox";
import Loader from "../Loader/loader";
import ObjectIdentifier from "../ObjectIdentifier";
import { useTheme } from "styled-components";

const ListItemBasic = ({
  title,
  description,
  icon,
  visible,
  rVisible,
  rTitle,
  rDescription,
  rIcon,
  darkest,
  padding,
  margin,
  children,
  action,
  isChecked,
  overflowX = "visible",
  overflowY = "visible",
  busy = false,
  borderRadius,
}: ListItemProps) => {
  const theme = useTheme();
  const isRightVisible = () => {
    if (rVisible === undefined) return !!rTitle || !!rIcon || !!rDescription;
    return (!!rTitle || !!rIcon || !!rDescription) && !!rVisible;
  };
  const getActionColor = () => {
    switch (action) {
      case ListItemAction.Primary:
        return theme.palette.Primary50;
      case ListItemAction.Success:
        return theme.palette.Green50;
      case ListItemAction.Warning:
        return theme.palette.Yellow50;
      case ListItemAction.Critical:
        return theme.palette.Red50;
      case ListItemAction.Secondary:
        return darkest ? theme.palette.White : theme.palette.Grey5;
      default:
        return darkest ? theme.palette.Grey5 : theme.palette.White;
    }
  };
  if (visible === false) return null;
  return (
    <HBox
      Margin={margin}
      BorderRadius={borderRadius}
      overflowX={overflowX}
      overflowY={overflowY}
    >
      <VBox
        flexGrow="1"
        backgroundColor={darkest ? theme.palette.Grey5 : theme.palette.White}
        Padding={padding || "20px"}
      >
        <HBox gap="5px" flexWrap="wrap">
          {children}
        </HBox>
        <HBox
          alignItems="center"
          justifyContent={isRightVisible() ? "space-between" : "flex-start"}
        >
          <HBox gap="20px" alignItems="center">
            {icon}
            <VBox>
              <ObjectIdentifier
                title={title}
                text={description?.toString() || ""}
              />
            </VBox>
          </HBox>
          <HBox
            height="100%"
            gap="20px"
            visible={isRightVisible()}
            alignItems={!rTitle || rIcon ? "flex-start" : "center"}
          >
            <VBox visible={isRightVisible()}>
              <ObjectIdentifier
                title={rTitle || ""}
                text={rDescription ? rDescription.toString() : ""}
                textAlign="right"
              />
            </VBox>
            {action === undefined && rIcon}
          </HBox>
        </HBox>
      </VBox>
      <HBox
        visible={action !== undefined}
        alignItems="center"
        justifyContent="center"
        width="60px"
        backgroundColor={darkest ? theme.palette.Grey5 : theme.palette.White}
      >
        <HBox Margin="0 10px 0 0">
          <Checkbox
            visible={action === ListItemAction.Checkbox}
            state={isChecked || false}
            title=""
          />
        </HBox>
        <VBox
          visible={action !== ListItemAction.Checkbox}
          BorderRadius="10px"
          justifyContent="center"
          alignItems="center"
          width="40px"
          height="40px"
          backgroundColor={getActionColor()}
        >
          {rIcon}
        </VBox>
      </HBox>
    </HBox>
  );
};

const ListItem = ({ onClick, busy = false, ...props }: ListItemProps) => {
  const [isChecked, setIsChecked] = useState(false);
  useEffect(() => {
    if (props.isChecked) setIsChecked(props.isChecked);
  }, []);
  const handleOnClick = () => {
    if (props.action === ListItemAction.Checkbox) {
      setIsChecked(!isChecked);
    }
    onClick?.();
  };
  if (busy)
    return (
      <HBox
        BorderRadius={props.borderRadius}
        height="42px"
        justifyContent="center"
        alignItems="center"
      >
        <Loader />
      </HBox>
    );
  if (onClick) {
    return (
      <ButtonOpacity onClick={handleOnClick}>
        <ListItemBasic {...props} isChecked={isChecked} />
      </ButtonOpacity>
    );
  }
  return <ListItemBasic {...props} />;
};

export default ListItem;
