import { DateTimePicker, Label } from "@ui5/webcomponents-react";
import {
  ErrorMessage,
  ErrorMessageWrapper,
} from "../../styles/TextInput.styled";
import { HBox, VBox } from "../../styles/Flexbox.styled";

import DateHelper from "../../utils/DateHelper";
import { DateInputProps } from "./IDateInput";
import Formatter from "../../utils/Formatter";
import Text from "../Text/Text";
import TextSizeEnum from "../../models/enums/TextSizeEnum";
import TextTypeEnum from "../../models/enums/TextTypeEnum";
import TextWeightEnum from "../../models/enums/TextWeightEnum";
import { getText } from "../../locales/initI18n";
import { useCallback } from "react";
import { useTheme } from "styled-components";

const DateInput = (props: DateInputProps) => {
  const { visible = true } = props;
  const theme = useTheme();
  const getValue = useCallback(() => {
    if (!props.value) return "";
    return Formatter.DateTime(props.value);
  }, [props.value]);

  const getLabel = () => {
    if (typeof props.children === "string")
      return <Label>{props.children}</Label>;
    return props.children;
  };

  if (!visible) return null;
  return (
    <VBox disabled={props.disabled} width="100%">
      {getLabel()}
      <HBox visible={!props.disabled} width="100%">
        <DateTimePicker
          ref={props.ref}
          valueStateMessage={
            <span>
              {getText("DateInput.WrongDateFormat")}{" "}
              {DateHelper.getDateTimeReadableFormat()}
            </span>
          }
          style={{ width: "100%" }}
          value={getValue()}
          formatPattern={DateHelper.getDateTimeFormat()}
          onChange={(e) => {
            const dateValue = e.target.dateValue;
            if (!dateValue) return;
            props.onChange?.(dateValue);
          }}
        />
      </HBox>
      <Text
        visible={!!props.disabled}
        type={TextTypeEnum.body}
        weight={TextWeightEnum.regular}
        size={TextSizeEnum.medium}
        color={theme.palette.Grey60}
      >
        {getValue()}
      </Text>
      <HBox
        justifyContent="center"
        backgroundColor={theme.palette.Red80}
        BorderRadius="20px"
      >
        <ErrorMessageWrapper>
          <ErrorMessage>{props.errors?.join(", ")}</ErrorMessage>
        </ErrorMessageWrapper>
      </HBox>
    </VBox>
  );
};

export default DateInput;
