import { MessageBoxAction, MessageBoxType } from "@ui5/webcomponents-react";

import { ConfirmDialogOptions } from "../hoc/withConfirmDialog/WithConfirmDialogProps";
import { PigeonStateEnum } from "../models/enums/PigeonStateEnum";
import { SeasonAssignmentGroup } from "../models/enums/SeasonAssignmentGroup";
import { getText } from "../locales/initI18n";

type OpenMessageFn = (
  message: string,
  options?: ConfirmDialogOptions | MessageBoxType
) => Promise<string | undefined>;

export default class ConfirmMessageHelper {
  static async getSeasonAssignment(openMessage: OpenMessageFn) {
    const textOld = getText("Pigeon.group.Old");
    const textYoung = getText("Pigeon.group.Young");
    const textBreeding = getText("Pigeon.group.Breeding");
    const actions = [textOld, textYoung, textBreeding, MessageBoxAction.Ignore];

    const assignMessage = await openMessage(
      `Czy chcesz dodać nowego gołębia do obecnego sezonu? Możesz dodać do młodych, dorosłych, rozpłodowych lub pominąć.`,
      {
        actions,
      }
    );

    switch (true) {
      case assignMessage === textOld:
        return SeasonAssignmentGroup.Old;
      case assignMessage === textYoung:
        return SeasonAssignmentGroup.Young;
      case assignMessage === textBreeding:
        return SeasonAssignmentGroup.Breeding;
      default:
        return null;
    }
  }

  static async getPigeonInActiveState(openMessage: OpenMessageFn) {
    const dead = getText("Pigeon.state.Dead");
    const lost = getText("Pigeon.state.Lost");
    const sold = getText("Pigeon.state.Sold");
    const other = getText("Pigeon.state.Other");
    const actions = [dead, lost, sold, other];
    const stateValue = await openMessage(
      "Gołąb jest nieaktywny. Nadaj mu odpowiedni status. Masz do wyboru: martwy, zgubiony, sprzedany lub inne.",
      { actions }
    );

    switch (true) {
      case stateValue === dead:
        return PigeonStateEnum.Dead;
      case stateValue === lost:
        return PigeonStateEnum.Lost;
      case stateValue === sold:
        return PigeonStateEnum.Sold;
      default:
        return PigeonStateEnum.Unknown;
    }
  }
}
