import { getText } from "../locales/initI18n";
import { isObjEmpty } from "./VariableHelper";
import { z } from "zod";

export const validateEmail = () =>
  z.string().email(getText("EmailInvalidMessage"));

export const validateGmail = () => {
  return validateEmail().refine((data) => data.includes("gmail.com"));
};

export const validatePassword = () =>
  z
    .string()
    .nonempty(getText("Validation.FieldRequired"))
    .min(6, getText("PasswordReset.Error.PasswordAtLeast6Characters"));

export const validatePhoneNumber = (isOptional = false) => {
  if (isOptional)
    return z
      .string()
      .min(9, getText("Validation.PhoneNumber"))
      .or(z.string().length(0));
  return z.string().min(9, getText("Validation.PhoneNumber"));
};

export const validateTrueBoolean = () => {
  return z
    .boolean()
    .refine((data) => data === true, getText("Validation.FieldRequired"));
};

export const validateUsername = () =>
  z
    .string()
    .nonempty(getText("Validation.FieldRequired"))
    .min(4, getText("Validation.Username4Characters"));

export const validateName = (isOptional = false) =>
  isOptional ? z.string().min(2).or(z.string().length(0)) : z.string().min(2);

export const validateLastName = (isOptional = false) =>
  isOptional ? z.string().min(2).or(z.string().length(0)) : z.string().min(2);

export const validateConfirmPassword = (password: string | undefined) =>
  z
    .string()
    .min(6, getText("PasswordReset.Error.PasswordAtLeast6Characters"))
    .refine(
      (data) => data === password,
      getText("PasswordReset.Error.DifferentPassword")
    );

export const validateRequired = () =>
  z.string().nonempty(getText("Validation.FieldRequired"));

export const validateObjectRequired = () =>
  z.any().refine((data) => {
    return !isObjEmpty(data);
  }, getText("Validation.FieldRequired"));

export const validateDateRequired = () =>
  z.date().refine((data) => {
    return data instanceof Date;
  }, getText("Validation.FieldRequired"));
