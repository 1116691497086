import {
  Error,
  ErrorBtn,
  ErrorMessage,
  ErrorMessageWrapper,
  ExpandErrorMessageWrapper,
  Input,
  InputContainer,
  InputWithError,
  InputWrapper,
  Label,
  TextArea,
} from "../styles/TextInput.styled";
import ITextInput, {
  TextInputState,
  TextInputType,
} from "../styles/ITextInput";
import React, { memo, useEffect, useRef, useState } from "react";
import { RightIcon, Type } from "../styles/ICustomInput";

import { autoCompleteHandler } from "../utils/utils";
import { getText } from "../locales/initI18n";

const OldTextInput: React.FC<ITextInput> = ({
  state = TextInputState.Default,
  inputType = TextInputType.Text,
  isErrorState = false,
  errors = [],
  placeholder,
  icon,
  isAutoCompleted,
  children,
  ...rest
}) => {
  const [type, setType] = useState<Type>("default");
  const [rightIcon, setRightIcon] = useState<RightIcon>("");
  const [active, setActive] = useState<boolean>(false);
  const inputRef = useRef<HTMLDivElement>(null);
  const [errorWidth, setErrorWidth] = useState<string>("300px");
  const [isAllErrors, setIsAllErrors] = useState<boolean>(false);

  const activeHandler = () => setActive(true);
  const blurHandler = () => setActive(false);

  const stateRightIcon = (): RightIcon => {
    if (!active && state === TextInputState.Success) return "icon-Success";
    if (!active && state === TextInputState.Error) return "icon-Error";
    return "";
  };

  const stateHandler = (): Type => {
    if (active) return "written active";
    let stateText: "success" | "error" | "" = "";
    if (state === TextInputState.Success) stateText = "success";
    if (state === TextInputState.Error || isErrorState) stateText = "error";
    if (!active && rest.value) return `written ${stateText}`;
    if (!active && !rest.value) return `default ${stateText}`;
    return "default";
  };

  useEffect(() => {
    if (isAutoCompleted) autoCompleteHandler(() => setType("written"));
    const textarea = document.querySelector(".textareaInput");
    if (!textarea) return;
    textarea.dispatchEvent(new Event("keyup", { bubbles: true }));
  }, []);

  useEffect(() => {
    const input = inputRef.current;
    setErrorWidth((input?.clientWidth || 0) - 150 + "px");
  }, [state]);

  useEffect(() => {
    setType(stateHandler());
    setRightIcon(stateRightIcon());
  }, [state, rest.value, active, errors.length, isErrorState]);

  const textAreaAdjust = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    const element = event.currentTarget;
    const parent = element.parentElement?.parentElement;
    element.style.height = "1px";
    element.style.height = `${35 + element.scrollHeight}px`;
    if (parent) {
      parent.style.minHeight = element.style.height;
      const grandParent = parent.parentElement;
      if (grandParent) {
        grandParent.style.minHeight = element.style.height;
      }
    }
  };

  const getPlaceholder = () => (active ? placeholder : "");

  const getInputType = () => {
    if (inputType === TextInputType.TextArea) {
      return (
        <TextArea
          ref={rest.inputRef}
          {...rest}
          onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
            rest?.onChange?.(event.target.value);
          }}
          rows={2}
          className="textareaInput"
          placeholder={getPlaceholder()}
          onKeyUp={(event) => textAreaAdjust(event)}
        />
      );
    }
    return (
      <Input
        {...rest}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          rest?.onChange?.(event.target.value);
        }}
        ref={rest.inputRef}
        placeholder={getPlaceholder()}
      />
    );
  };

  const getLabel = () => {
    if (typeof children === "string") return <Label>{children}</Label>;
    return children;
  };
  return (
    <InputWithError ref={inputRef}>
      <InputContainer
        onFocus={activeHandler}
        onBlur={blurHandler}
        className={`${type}`}
      >
        {/* <Icon className={`${icon || "icon-search"} left-icon`} /> */}
        <InputWrapper>
          {getLabel()}
          {getInputType()}
        </InputWrapper>
        {/* <Icon className={`${rightIcon} right-icon`} /> */}
      </InputContainer>
      <Error
        visible={state === TextInputState.Error || isErrorState}
        isExpand={isAllErrors}
        className="error"
      >
        {!isAllErrors && (
          <ErrorMessageWrapper calculatedWidth={errorWidth}>
            <ErrorMessage>{errors.join(", ")}</ErrorMessage>
          </ErrorMessageWrapper>
        )}
        {isAllErrors && (
          <ExpandErrorMessageWrapper>
            {errors.map((error, index) => (
              <li key={index}>
                <ErrorMessage>{error}</ErrorMessage>
              </li>
            ))}
          </ExpandErrorMessageWrapper>
        )}
        <ErrorBtn onClick={() => setIsAllErrors((value) => !value)}>
          {isAllErrors
            ? getText("TextInput.ShowLess")
            : getText("TextInput.ShowMore")}
        </ErrorBtn>
      </Error>
    </InputWithError>
  );
};

export default memo(OldTextInput, (prevProps, nextProps) => {
  return (
    prevProps.value === nextProps.value &&
    prevProps.state === nextProps.state &&
    prevProps.isErrorState === nextProps.isErrorState &&
    prevProps.errors === nextProps.errors &&
    prevProps.placeholder === nextProps.placeholder &&
    prevProps.icon === nextProps.icon &&
    prevProps.isAutoCompleted === nextProps.isAutoCompleted &&
    prevProps.children === nextProps.children
  );
});
