import { Body, Caption, Footer } from "./Typography.styled";
import { HBox, VBox } from "./Flexbox.styled";
import {
  activeImage,
  activeInput,
  defaultPlaceholder,
  errorBar,
  errorImage,
  errorInput,
  input,
  inputContainer,
  inputWrapper,
  placeholder,
  successImage,
  successInput,
  writtenPlaceholder,
} from "./CustomInputStyle.styled";

import styled from "styled-components";

export const InputWithError = styled(VBox)`
  width: 100%;
`;

export const InputContainer = styled(HBox)`
  ${inputContainer}
  & .right-icon {
    display: none;
  }
  &.default {
    p {
      ${defaultPlaceholder}
    }
  }
  &.written {
    p {
      ${writtenPlaceholder}
    }
  }
  &.active {
    ${activeInput}
    .left-icon:before {
      ${activeImage}
    }
  }
  &.success {
    ${successInput}
    .right-icon {
      ${successImage}
    }
  }
  &.error {
    ${errorInput}
    .right-icon,
    .error {
      ${errorImage}
    }
  }
`;

export const InputWrapper = styled.div`
  ${inputWrapper}
`;

export const Label = styled.p`
  ${placeholder}
`;

export const Input = styled.input`
  ${input}
`;

export const TextArea = styled.textarea`
  ${input}
  top: 20px;
  &::-webkit-resizer {
    display: none;
  }
`;

export const Error = styled.div<{ visible: boolean; isExpand: boolean }>`
  justify-content: center;
  padding: 5px;
  text-align: center;
  border-radius: 20px;
  position: relative;
  background-color: ${({ theme }) => theme.palette.Red80};
  display: ${({ visible }) => (visible ? "flex" : "none")};

  cursor: pointer;

  ${({ isExpand }) =>
    isExpand &&
    `
    flex-wrap: wrap;
    flex-direction: column;
    padding: 0 20px 20px 20px;
  `}

  ::before {
    content: " ";
    position: absolute;
    background-color: ${({ theme }) => theme.palette.Red80};
    width: 20px;
    height: 37px;
    z-index: -1;
    top: -20px;
    left: 0;
  }

  ::after {
    content: " ";
    position: absolute;
    background-color: ${({ theme }) => theme.palette.Red80};
    width: 20px;
    height: 37px;
    z-index: -1;
    top: -20px;
    right: 0;
  }
`;

export const ErrorMessageWrapper = styled.div<{ calculatedWidth?: string }>`
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${({ theme }) => theme.palette.White};
  overflow: hidden;
  width: ${({ calculatedWidth }) => {
    return calculatedWidth ? calculatedWidth : "auto";
  }};
  padding-right: 2px;
`;

export const ExpandErrorMessageWrapper = styled.ul`
  color: ${({ theme }) => theme.palette.White};
`;

export const ErrorMessage = styled.span`
  ${Caption("regular", "medium")}
`;

export const ErrorBtn = styled.span`
  ${Caption("regular", "small")}
  padding: 2px;
  color: ${({ theme }) => theme.palette.Red80};
  background: ${({ theme }) => theme.palette.White};
  border-radius: 5px;
  white-space: nowrap;
`;
