import { ComponentType, createContext, useState } from "react";
import { MessageBox, MessageBoxType } from "@ui5/webcomponents-react";
import WithConfirmDialogProps, {
  ConfirmDialogOptions,
} from "./WithConfirmDialogProps";

const MessageContext = createContext<WithConfirmDialogProps | undefined>(
  undefined
);

function withConfirmDialog<P extends WithConfirmDialogProps>(
  Component: ComponentType<P>
) {
  return (props: Omit<P, keyof WithConfirmDialogProps>) => {
    const [isVisible, setIsVisible] = useState(false);
    const [Message, SetMessage] = useState<string>("");
    const [Type, SetType] = useState<MessageBoxType>();
    const [Actions, SetActions] = useState<string[]>([]);
    const [onConfirm, setOnConfirm] = useState<
      (action: string | undefined, escPressed?: boolean) => Promise<void>
    >(async (action: string | undefined, escPressed?: boolean) => {
      setIsVisible(false);
    });

    const open = async (
      message: string,
      options: ConfirmDialogOptions | MessageBoxType = {}
    ) => {
      const type = typeof options === "string" ? options : options?.type;
      const actions = typeof options === "string" ? [] : options?.actions || [];
      setIsVisible(true);
      SetMessage(message);
      SetType(type || MessageBoxType.Confirm);
      SetActions(actions);
      return new Promise<string | undefined>((resolve) => {
        setOnConfirm(
          () => async (action: string | undefined, escPressed?: boolean) => {
            setIsVisible(false);
            resolve(action);
          }
        );
      });
    };

    return (
      <MessageContext.Provider value={{ openMessage: open }}>
        <MessageBox
          type={Type}
          open={isVisible}
          actions={Actions}
          onClose={onConfirm}
        >
          {Message}
        </MessageBox>
        <Component {...(props as P)} openMessage={open} />
      </MessageContext.Provider>
    );
  };
}

export { MessageContext };

export default withConfirmDialog;
