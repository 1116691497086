import { FieldInstance } from "houseform";
import { HBox } from "../../styles/Flexbox.styled";
import TextInput from "../TextInput";
import { TextInputType } from "../../styles/ITextInput";

export interface TextFormInputProps extends FieldInstance<string, any> {
  title: string;
  placeholder: string;
  type?: string;
  inputType?: TextInputType;
}

const TextFormInput = ({
  type = "text",
  inputType = TextInputType.Text,
  ...props
}: TextFormInputProps) => {
  return (
    <HBox width="100%" gap="10px">
      <TextInput
        type={type}
        inputType={inputType}
        width="100%"
        placeholder={props.placeholder}
        errors={props.errors}
        isErrorState={false}
        value={props.value}
        onBlur={props.onBlur}
        onChange={(e: string) => props.setValue(e)}
      >
        {props.title}
      </TextInput>
    </HBox>
  );
};

export default TextFormInput;
