import CookieHelper from "../utils/CookieHelper";
import { Flight } from "../models/Flight";
import { FlightType } from "../models/enums/FlightType";
import { getFlight } from "../services/FlightService";
import { useHookstate } from "@hookstate/core";
import { useQueryClient } from "@tanstack/react-query";
import useUser from "./useUser";

const useFlight = (useStorage: boolean = false) => {
  const state = useHookstate({ flight: new Flight() });
  const { data: user } = useUser();
  const queryClient = useQueryClient();

  const getQueryKey = (flightId: number) => ["flight", flightId.toString()];

  const get = async (flightId?: number) => {
    const fetchedFlight = await getInternal(flightId);
    if (!fetchedFlight.canBeRender()) {
      CookieHelper.flightId.remove();
    } else if (useStorage && !fetchedFlight.isNew()) {
      CookieHelper.flightId.set(fetchedFlight.id.toString());
    }

    state.set({ flight: Flight.create(fetchedFlight) });
    return fetchedFlight;
  };

  const getInternal = async (flightId?: number): Promise<Flight> => {
    const id = getInternalIndex(flightId);
    if (!id) return new Flight();
    const fetchedFlight = await queryClient.ensureQueryData(
      getQueryKey(id),
      () => {
        return getFlight(Number(id));
      }
    );
    if (!fetchedFlight) return new Flight();
    if (fetchedFlight.type === FlightType.Single) return fetchedFlight;
    const related = fetchedFlight.relatedFlights.find(
      (flight) => flight.user.id === user?.id
    );
    if (related) return await getInternal(related.id);
    return fetchedFlight;
  };

  const getInternalIndex = (flightId?: number) => {
    if (useStorage) {
      const cookieFlightId = CookieHelper.flightId.get();
      if (!flightId && !cookieFlightId) return null;
      return flightId || Number(cookieFlightId);
    }
    return flightId || null;
  };

  const fetch = async (flightId: number) => {
    const fetchedFlight = await getFlight(Number(flightId));
    if (!fetchedFlight) {
      const newFlight = new Flight();
      state.set({ flight: newFlight });
      return newFlight;
    }
    queryClient.setQueryData(getQueryKey(flightId), fetchedFlight);
    state.set({ flight: Flight.create(fetchedFlight) });
    return fetchedFlight;
  };

  const flight = () => state.get({ noproxy: true }).flight as Flight;
  return { getFlight: flight, fetch, get, setter: state.flight };
};

export default useFlight;
