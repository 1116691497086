import Cookies from "js-cookie";

class Cookie {
  private _name: string;

  constructor(name: string) {
    this._name = name;
  }

  get() {
    return Cookies.get(this._name) || "";
  }

  set(value: string) {
    Cookies.set(this._name, value);
  }

  remove() {
    Cookies.remove(this._name);
  }
}

export default class CookieHelper {
  static token = new Cookie("token");
  static flightId = new Cookie("flightId");
  static season = new Cookie("season");
  static pigeonSeasonPageTab = new Cookie("pigeonSeasonPageTab");
}
