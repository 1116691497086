import { AuthState } from "./state";
import CookieHelper from "../../../utils/CookieHelper";
import { PayloadAction } from "@reduxjs/toolkit";

const setAuth = (state: AuthState, action: PayloadAction<AuthState>) => {
  const { token } = action.payload;
  state.token = token;
  CookieHelper.token.set(token);
};

const resetAuth = (state: AuthState) => {
  state.token = "";
  CookieHelper.token.remove();
};

const reducers = {
  setAuth,
  resetAuth,
};

export default reducers;
