import { useEffect, useState } from "react";

import CookieHelper from "../utils/CookieHelper";
import { PigeonTabEnum } from "../models/enums/PigeonTabEnum";
import { init } from "i18next";

export const usePigeonViewTab = (defaultTab: PigeonTabEnum) => {
  const [tab, setTab] = useState(defaultTab);

  const getStoragedTab = () => CookieHelper.pigeonSeasonPageTab.get();

  /**
   * Old, Young, Breeding
   */
  const isGroupTabs = (tab: string) => {
    return (
      tab === PigeonTabEnum.Old ||
      tab === PigeonTabEnum.Young ||
      tab === PigeonTabEnum.Breeding
    );
  };

  const isPairTabs = (tab: string) => {
    return tab === PigeonTabEnum.Pair;
  };

  const isChildTabs = (tab: string) => {
    return tab === PigeonTabEnum.Chick || tab === PigeonTabEnum.Egg;
  };

  const getTabEnum = (tab?: string) => {
    switch (tab) {
      case PigeonTabEnum.Pair:
        return PigeonTabEnum.Pair;
      case PigeonTabEnum.Breeding:
        return PigeonTabEnum.Breeding;
      case PigeonTabEnum.Old:
        return PigeonTabEnum.Old;
      case PigeonTabEnum.Young:
        return PigeonTabEnum.Young;
      case PigeonTabEnum.Chick:
        return PigeonTabEnum.Chick;
      case PigeonTabEnum.Egg:
        return PigeonTabEnum.Egg;
      default:
        return defaultTab;
    }
  };

  const setPigeonTab = (value: string) => {
    CookieHelper.pigeonSeasonPageTab.set(value);
    setTab(getTabEnum(value));
  };

  useEffect(() => {
    setTab(getTabEnum(getStoragedTab()));
  }, []);

  return {
    tab,
    getStoragedTab,
    setTab: setPigeonTab,
    isGroupTabs,
    isPairTabs,
    isChildTabs,
  };
};
