import { Button, ButtonType } from "../styles/Button.styled";
import { HBox, ResponsiveVBox, VBox } from "../styles/Flexbox.styled";
import { deleteAccount, requestDeleteAccount } from "../services/AuthService";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Bar from "../components/Bar/Bar";
import { BarType } from "../components/Bar/IBar";
import Confirm from "../components/modals/Confirm/Confirm";
import ConfirmModalTypeEnum from "../models/enums/ConfirmModalTypeEnum";
import { DeleteAccountModal } from "../models/enums/DeleteAccountModal";
import Logo from "../components/Logo/Logo";
import Text from "../components/Text/Text";
import TextInput from "../components/TextInput";
import TextSizeEnum from "../models/enums/TextSizeEnum";
import TextTypeEnum from "../models/enums/TextTypeEnum";
import TextWeightEnum from "../models/enums/TextWeightEnum";
import { getText } from "../locales/initI18n";
import { useTheme } from "styled-components";
import useUser from "../hooks/useUser";
import { validateEmail } from "../utils/ValidationHelper";

const DeleteAccount = () => {
  const { token } = useParams();
  const theme = useTheme();
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>("");
  const [modal, setModal] = useState<DeleteAccountModal>(
    DeleteAccountModal.NONE
  );

  const user = useUser();

  const onRequest = async () => {
    await requestDeleteAccount(email);
    setModal(DeleteAccountModal.AFTER_REQUEST);
  };

  const onDeleteAccount = async () => {
    if (token) await deleteAccount(token);
    setModal(DeleteAccountModal.AFTER_DELETE);
  };

  useEffect(() => {
    if (user?.data?.email) {
      setEmail(user?.data?.email);
    }
  }, [user?.data]);

  return (
    <VBox
      width="100%"
      justifyContent="center"
      alignItems="center"
      backgroundColor={theme.palette.White}
      Padding="10px"
      overflowY="scroll"
    >
      <Confirm
        type={ConfirmModalTypeEnum.Change}
        isVisible={modal === DeleteAccountModal.AFTER_DELETE}
        title={getText("DeleteAccount.AfterDeleteTitle")}
        description={getText("DeleteAccount.AfterDeleteDescription")}
        isCancel={false}
        confirmText={getText("DeleteAccount.Confirmation")}
        onConfirm={() => navigate("/")}
        onClose={() => navigate("/")}
      />
      <Confirm
        type={ConfirmModalTypeEnum.Change}
        isVisible={modal === DeleteAccountModal.AFTER_REQUEST}
        title={getText("DeleteAccount.AfterSendingMailTitle")}
        description={getText("DeleteAccount.AfterSendingMailDescription")}
        isCancel={false}
        confirmText={getText("DeleteAccount.Confirmation")}
        onConfirm={() => navigate("/")}
        onClose={() => navigate("/")}
      />
      <Logo />
      <ResponsiveVBox
        backgroundColor={theme.palette.White}
        shadow="small"
        RIsShadowSM={false}
        BorderRadius="20px"
        alignItems="center"
        Padding="20px"
        gap="20px"
        maxWidth="480px"
        Margin="0 5px"
      >
        <Text
          type={TextTypeEnum.h3}
          weight={TextWeightEnum.medium}
          size={TextSizeEnum.small}
          color={theme.palette.Grey80}
        >
          {getText("DeleteAccount.Title")}
        </Text>
        <Bar type={BarType.Info} visible={!token}>
          {getText("DeleteAccount.Description")}
        </Bar>
        <Bar type={BarType.Critical} visible={!!token}>
          {getText("DeleteAccount.DeleteConfirmationDescription")}
        </Bar>
        <HBox visible={!token} width="100%">
          <TextInput
            onChange={(e) => setEmail(e)}
            value={email}
            placeholder="jan.kowalski@email.com"
          >
            {getText("DeleteAccount.InputTitle")}
          </TextInput>
        </HBox>
        <Button
          width="100%"
          buttonType={ButtonType.Primary}
          disabled={!validateEmail().safeParse(email).success}
          visible={!token}
          onClick={onRequest}
        >
          {getText("DeleteAccount.SendMailAction")}
        </Button>
        <Button
          onClick={onDeleteAccount}
          width="100%"
          buttonType={ButtonType.Critical}
          visible={!!token}
        >
          {getText("DeleteAccount.DeleteAccountAction")}
        </Button>
        <Button
          width="100%"
          buttonType={ButtonType.Ghost}
          onClick={() => navigate("/dashboard")}
        >
          {getText("GoToStart")}
        </Button>
      </ResponsiveVBox>
    </VBox>
  );
};

export default DeleteAccount;
