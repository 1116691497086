import React, { ComponentType, ReactNode, useState } from "react";

import BaseDialogProps from "../../models/BaseDialogProps";
import DialogResult from "../../models/DialogResult";
import { FlexBox } from "@ui5/webcomponents-react";

// Interfejs dla propsów przekazywanych przez HOC
export interface WithDialogsProps {
  openDialog: (
    dialog: string,
    props?: BaseDialogProps
  ) => Promise<DialogResult>;
}

// Typ funkcji renderującej dialog
export type RenderDialogFn = (
  dialog: string,
  props: BaseDialogProps,
  onClose: (value: DialogResult) => void
) => ReactNode;

// HOC z renderDialog jako parametrem
function withDialogs<P extends WithDialogsProps>(
  WrappedComponent: ComponentType<P>,
  renderDialog: RenderDialogFn
) {
  let resolveDialog: ((value: DialogResult) => void) | null = null;
  return (props: Omit<P, keyof WithDialogsProps>) => {
    const [currentDialog, setCurrentDialog] = useState<string | null>(null);
    const [dialogProps, setDialogProps] = useState<BaseDialogProps | null>(
      null
    );

    const openDialog = (
      dialog: string,
      props: BaseDialogProps = {}
    ): Promise<DialogResult> => {
      return new Promise<DialogResult>((resolve) => {
        setCurrentDialog(dialog);
        setDialogProps(props);
        resolveDialog = resolve;
      });
    };

    const hideDialog = (value: DialogResult) => {
      setCurrentDialog(null);
      setDialogProps(null);
      if (resolveDialog) {
        resolveDialog(value);
        resolveDialog = null;
      }
    };

    return (
      <>
        <WrappedComponent
          {...(props as P)}
          openDialog={openDialog}
          hideDialog={hideDialog}
        />
        {currentDialog &&
          dialogProps &&
          renderDialog(currentDialog, dialogProps, hideDialog)}
      </>
    );
  };
}

export default withDialogs;
