import { Button, ButtonType } from "../../../styles/Button.styled";
import { HBox, VBox } from "../../../styles/Flexbox.styled";
import { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";

import Bar from "../../Bar/Bar";
import { BarType } from "../../Bar/IBar";
import { Branch } from "../../../models/Branch";
import BranchList from "../BranchList/BranchList";
import { CloseIconEnum } from "../../HalfModal/CloseIconEnum";
import { Country } from "../../../models/Country";
import CountryList from "../../CountryList/CountryList";
import { District } from "../../../models/District";
import DistrictList from "../DistrictList/DistrictList";
import HalfModal from "../../HalfModal/HalfModal";
import { Section } from "../../../models/Section";
import SectionList from "../SectionList/SectionList";
import TagButton from "../../TagButton/TagButton";
import { fillDistrict } from "../../../services/UserService";
import { getText } from "../../../locales/initI18n";
import { resetAuth } from "../../../redux/modules/auth";
import { setError } from "../../../redux/modules/error";
import { useAppDispatch } from "../../../redux/hooks";
import { useNavigate } from "react-router-dom";
import useUser from "../../../hooks/useUser";

const DistrictChecker = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  const [district, setDistrict] = useState<District | null>(null);
  const [branch, setBranch] = useState<Branch | null>(null);
  const [section, setSection] = useState<Section | null>(null);

  const [isDistrictList, setIsDistrictList] = useState(false);
  const [isBranchList, setIsBranchList] = useState(false);
  const [isSectionList, setIsSectionList] = useState(false);

  const [districtNumber, setDistrictNumber] = useState<number | null>(null);

  const [busy, setBusy] = useState(false);

  const { data, isLoading, isError, error } = useUser();

  useEffect(() => {
    if (isError) {
      dispatch(setError(error));
      navigate("/");
    }
  }, [isError, error]);

  const user = data;

  const logout = () => {
    queryClient.removeQueries();
    dispatch(resetAuth());
    navigate("/");
  };

  const fillData = async () => {
    setBusy(true);
    try {
      await fillDistrict(district, branch, section);
      navigate("/dashboard/profile");
    } catch (e) {
      dispatch(setError(e));
    } finally {
      setBusy(false);
    }
  };

  if (isLoading) return null;
  if (districtNumber === 0) return null;
  if (!!user?.district && !!user?.branch && !!user?.section) return null;
  return (
    <>
      <DistrictList
        isVisible={isDistrictList}
        onClose={() => setIsDistrictList(false)}
        country={user?.country}
        distinctCount={(count) => setDistrictNumber(count)}
        onConfirm={(data) => {
          setDistrict(data as District);
          setIsDistrictList(false);
        }}
      />
      <BranchList
        isVisible={isBranchList}
        onClose={() => setIsBranchList(false)}
        onConfirm={(data) => {
          setBranch(data);
          setIsBranchList(false);
        }}
        districtId={district?.id}
      />
      <SectionList
        isVisible={isSectionList}
        onClose={() => setIsSectionList(false)}
        onConfirm={(data) => {
          setSection(data);
          setIsSectionList(false);
        }}
        districtId={district?.id}
        branchId={branch?.id}
      />
      <HalfModal
        heightDivider={1.1}
        Padding="0 20px 20px 20px"
        visible={districtNumber !== null}
        isExpander={false}
        title={getText("DistrictChecker.CompleteData")}
        closeIcon={CloseIconEnum.WITHOUT_BUTTON}
        busy={busy}
      >
        <Bar
          type={BarType.Warn}
          title={getText("DistrictChecker.YourAccountHasIncompleteData")}
        >
          {getText("DistrictChecker.ToUseTheApp")}
        </Bar>
        <VBox gap="10px" Margin="20px 0 0 0">
          <HBox width="100%" gap="10px">
            <TagButton
              title={getText("General.District")}
              placeholder={getText("DistrictChecker.SelectAppropriateDistrict")}
              value={district?.name}
              onClick={() => {
                setIsDistrictList(true);
              }}
              disabled={false}
            />
          </HBox>
          <HBox width="100%" gap="10px">
            <TagButton
              title={getText("General.Branch")}
              placeholder={getText("DistrictChecker.FirstSelectDistrict")}
              value={branch?.name}
              error={
                district?.id !== branch?.districtId
                  ? getText("DistrictChecker.BranchDoesNotBelongToDistrict")
                  : undefined
              }
              onClick={() => {
                setIsBranchList(true);
              }}
              disabled={!district}
            />
          </HBox>
          <HBox width="100%" gap="10px">
            <TagButton
              title={getText("General.Section")}
              placeholder={getText("DistrictChecker.FirstSelectBranch")}
              value={section?.name}
              error={
                section?.branchId !== branch?.id
                  ? getText("DistrictChecker.SectionDoesNotBelongToBranch")
                  : undefined
              }
              onClick={() => {
                setIsSectionList(true);
              }}
              disabled={!branch}
            />
          </HBox>
          <VBox gap="10px">
            <Button
              buttonType={ButtonType.Primary}
              width="100%"
              onClick={fillData}
              disabled={!district || !branch || !section}
            >
              {getText("DistrictChecker.CompleteData")}
            </Button>
            <Button buttonType={ButtonType.Ghost} width="100%" onClick={logout}>
              {getText("DistrictChecker.Logout")}
            </Button>
          </VBox>
        </VBox>
      </HalfModal>
    </>
  );
};

export default DistrictChecker;
