import { FilterName } from "../enums/FilterName";
import BaseFilter from "./BaseFilter";
import ChildFilter from "./ChildFilter";
import { IBaseFilter } from "./IBaseFilter";
import IChildFilter from "./IChildFilter";
import IParentFilter from "./IParentFilter";
import { ParentFilterOperator } from "./ParentFilterOperator";

export default class ParentFilter extends BaseFilter implements IParentFilter {
  private filters: IBaseFilter[] = [];
  private operator: ParentFilterOperator = ParentFilterOperator.Or;

  constructor(filters: IBaseFilter[] = [], operator = ParentFilterOperator.Or) {
    super();
    this.filters = [...filters];
    this.operator = operator;
  }

  //#region Getters
  public get Filters(): IBaseFilter[] {
    return this.filters;
  }

  public get Operator(): ParentFilterOperator {
    return this.operator;
  }
  //#endregion

  //#region Setters
  get Name(): string {
    return this.getChilds()
      .map((child) => child.Name)
      .join(" ");
  }
  //#endregion

  //#region Public methods
  addFilter(filter: IBaseFilter): void {
    this.filters.push(filter);
  }
  public getChilds(): IChildFilter[] {
    const childs: IChildFilter[] = [];

    this.filters.forEach((filter) => {
      if (ParentFilter.is(filter)) {
        childs.push(...filter.getChilds());
      } else if (ChildFilter.is(filter)) {
        childs.push(filter);
      }
    });

    return childs;
  }
  public getChild(name: FilterName) {
    return this.getChilds().find((child) => child.Name === name);
  }
  transform(): string {
    let rawFilters: string = "";
    for (const filter of this.Filters) {
      const value = filter.transform();
      if (!value) continue;
      if (rawFilters) {
        if (ParentFilter.is(filter))
          rawFilters = `${rawFilters}${this.Operator}(${value})`;
        else if (ChildFilter.is(filter))
          rawFilters = `${rawFilters}${this.Operator}${value}`;
      } else {
        if (ParentFilter.is(filter)) rawFilters = `(${value})`;
        else if (ChildFilter.is(filter)) rawFilters = value;
      }
    }
    return rawFilters;
  }
  //#endregion

  //#region Private methods

  //#endregion

  static override is(object: unknown): object is IParentFilter {
    return object instanceof ParentFilter;
  }
}
