import { OrderEnum } from "./enums/OrderEnum";
import Page from "./Page";

export interface PageOptionsProps {
  page?: number;
  take?: number;
  relations?: string;
  filters?: string;
}

export default class PageOptions {
  order: OrderEnum = OrderEnum.ASC;
  page: number = 1;
  take: number = 10;
  relations: string | undefined;
  filters: string | undefined = "";

  get skip(): number {
    return (this.page - 1) * this.take;
  }

  constructor();
  constructor(options?: PageOptionsProps);
  constructor(options?: PageOptionsProps) {
    this.page = options?.page || this.page;
    this.take = options?.take || this.take;
    this.relations = options?.relations;
    this.filters = options?.filters || this.filters;
  }

  public getQueryKey(key: string) {
    return [key, { filters: this.filters, relations: this.relations }];
  }

  public setPage(page: number) {
    this.page = page;
    return this;
  }

  nextPagefn(lastPage: Page<unknown>, pages: Page<unknown>[]) {
    if (!lastPage.meta.isNext) return this.page;
    this.page = lastPage.meta.page + 1;
    return this.page;
  }

  private _getFilterString() {
    return this.filters ? `&filters=${encodeURIComponent(this.filters)}` : "";
  }

  private _getRelationsString() {
    return this.relations ? `&relations=${this.relations}` : "";
  }

  getParams() {
    const filterString = this._getFilterString();
    const relationsString = this._getRelationsString();
    return `page=${this.page}&take=${this.take}${filterString}${relationsString}`;
  }
}
