import { CreateFlight, Flight, JoinFlight } from "../models/Flight";
import {
  authDelete,
  authGet,
  authPatch,
  authPost,
  authPut,
} from "./BaseService";

import BaseStatistics from "../models/BaseStatistics";
import { FlightMarker } from "../models/Marker";
import { FlightSharing } from "../models/FlightSharing";
import Page from "../models/Page";
import PageOptions from "../models/PageOptions";

export const getUserFlights = async (options: PageOptions) => {
  return getFlights("user", options);
};

export const getEventsFlights = async (options: PageOptions) => {
  return getFlights("event", options);
};

export const getEventsCount = async () => {
  const data = await authGet<number>(`flight/count/event`);
  return data.data;
};

export const getSharedFlights = async (options: PageOptions) => {
  return getFlights("shared", options);
};

export const getNewSharedCount = async () => {
  const data = await authGet<number>(`flight/count/shared`);
  return data.data;
};

export const getPrivateEventsFlights = async (options: PageOptions) => {
  return getFlights("related", options);
};

export const getPrivateEventsCount = async () => {
  const data = await authGet<number>(`flight/count/related`);
  return data.data;
};

export const getFlights = async (
  type: "user" | "event" | "shared" | "related",
  options: PageOptions
) => {
  const result = await authGet<object>(
    `flight/${type}/?${options.getParams()}`
  );
  const page = new Page<Flight>().fillData(result.data);
  page.data = page.data.map((item) => Flight.create(item));
  return page;
};

export const getFlight = async (flightId: number) => {
  if (!flightId) return null;
  const data = await authGet<object>(`flight/id/${flightId}`);
  return Flight.create(data.data);
};

export const addFlight = async (flight: CreateFlight) => {
  const data = await authPost<unknown>(`flight`, flight);
  return data.data as Flight;
};

export const joinToFlight = async (joinFlight: JoinFlight) => {
  const data = await authPost<Flight>(
    `flight/${joinFlight.flightId}/join`,
    joinFlight
  );
  return data.data;
};

export const updateFlight = async (flight: Partial<CreateFlight>) => {
  const data = await authPut<unknown>(`flight/${flight.id}`, flight);
  return flight as Flight;
};

export const updateFlightMarker = async (
  flightId?: number,
  id?: number,
  remarks?: string,
  realDate?: Date,
  userDate?: Date
) => {
  if (!id || !flightId) return;
  const data = await authPatch<unknown>(`flight/${flightId}/coordinate/${id}`, {
    realDate: realDate?.toISOString(),
    userDate: userDate?.toISOString(),
    remarks,
  });
  return data.data as FlightMarker;
};

export const removeFlight = async (flightId: number) => {
  const data = await authDelete<unknown>(`flight/${flightId}`);
  return data.data as Flight;
};

export const addToPrivateFlight = async (
  flightId: number,
  userIds: number[]
) => {
  const data = await authPost<Flight>(
    `flight/create-privateEvent/${flightId}`,
    {
      userIds,
    }
  );
  return data;
};

export const addPlanners = async (flightId: number, userIds: number[]) => {
  const data = await authPost<Flight>(`flight/planner/${flightId}`, {
    userIds,
  });
  return data;
};

export const shareFlight = async (flightId: number, userIds: number[]) => {
  const data = await authPost<object[]>(`flight/share/${flightId}`, {
    userIds,
  });
  if (!data.data) return [];
  return data.data.map((item) => FlightSharing.create(item));
};

export const rejectShareFlight = async (flightId: number, userId: number) => {
  const data = await authDelete<unknown>(`flight/share/${flightId}/${userId}`);
  return data;
};

export const finishFlight = async (flightId: number, endDate: string) => {
  const data = await authPost(`flight/finish/${flightId}`, {
    endDate,
  });
  return data;
};

export const getFlightsStatistics = async () => {
  const data = await authGet<object>(`flight/statistics`);
  return BaseStatistics.create(data.data);
};

export const getUserFlightsStatistics = async (userId: number) => {
  const data = await authGet<object>(`flight/statistics/${userId}`);
  return BaseStatistics.create(data.data);
};
