import { authDelete, authGet, authPost } from "./BaseService";

import Page from "../models/Page";
import PageOptions from "../models/PageOptions";
import PigeonPair from "../models/PigeonPair";

export const addPair = ({ male, female, season }: PigeonPair) => {
  return authPost("pigeon-pair", {
    maleId: male.id,
    femaleId: female.id,
    year: season.year,
  });
};

export const getPigeonPairs = async (options: PageOptions) => {
  const result = await authGet<object[]>(`pigeon-pair?${options.getParams()}`);

  const page = new Page<PigeonPair>().fillData(result.data);
  page.data = page.data.map((item) => PigeonPair.create(item));
  return page;
};

export const getForPigeonAndYear = async (pigeonId: number, year: number) => {
  if (!pigeonId) return [];
  const result = await authGet<object[]>(
    `pigeon-pair/pigeon/${pigeonId}/year/${year}`
  );
  return result.data.map((item) => PigeonPair.create(item));
};

export const removePair = (id: number) => {
  return authDelete(`pigeon-pair/${id}`);
};
