import {
  Button,
  ButtonDomRef,
  Menu,
  MenuItem,
  MenuPropTypes,
} from "@ui5/webcomponents-react";
import { memo, useRef, useState } from "react";

export interface MenuButtonProps extends MenuPropTypes {
  buttonTitle?: string;
  onButtonClick?: () => void;
}

const MenuButton = (props: MenuButtonProps) => {
  const button = useRef<ButtonDomRef>(null);
  const [isOpen, setIsOpen] = useState(false);

  const onButtnClick = () => {
    setIsOpen(!isOpen);
    props.onButtonClick?.();
  };

  const getDomButton = () => {
    if (button.current) {
      return button.current.getDomRef?.() || "";
    }
  };

  return (
    <>
      <Button ref={button} onClick={onButtnClick}>
        {props.buttonTitle || "Open Menu"}
      </Button>
      <Menu {...props} opener={getDomButton()} open={isOpen}>
        {props.children}
      </Menu>
    </>
  );
};

export default memo(MenuButton);
