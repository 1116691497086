import { Base } from "./Base";

export default class UsersStatistics extends Base {
  total: number = 0;
  planners: number = 0;
  oneDayUsers: number = 0;
  twoDaysUsers: number = 0;
  todayActivity: number = 0;
  twoDaysActivity: number = 0;
  neverLoggedIn: number = 0;
}
