import { Button, ButtonType } from "../../styles/Button.styled";
import { HBox, VBox } from "../../styles/Flexbox.styled";
import {
  IoArrowUp,
  IoChevronDownOutline,
  IoChevronUpOutline,
  IoRainyOutline,
  IoThermometerOutline,
} from "react-icons/io5";
import { useEffect, useState } from "react";

import { ButtonOpacity } from "../../styles/ButtonOpacity.styled";
import { LayersProps } from "./ILayers";
import { MapLayerEnum } from "../../models/enums/MapLayerEnum";
import Text from "../Text/Text";
import TextSizeEnum from "../../models/enums/TextSizeEnum";
import TextTypeEnum from "../../models/enums/TextTypeEnum";
import TextWeightEnum from "../../models/enums/TextWeightEnum";
import { getText } from "../../locales/initI18n";
import { useTheme } from "styled-components";

const Layers = ({ initLayer, onChangeLayer, visible = true }: LayersProps) => {
  const theme = useTheme();
  const [currentLayer, setCurrentLayer] = useState<MapLayerEnum>(initLayer);
  const [isExpand, setIsExpand] = useState<boolean>(false);

  useEffect(() => {
    setCurrentLayer(initLayer);
  }, [initLayer]);

  const onChange = (layer: MapLayerEnum) => {
    setCurrentLayer(layer);
    onChangeLayer(layer);
  };

  const getActiveIcon = () => {
    if (currentLayer === MapLayerEnum.Wind) {
      return <IoArrowUp size={20} color={theme.palette.White} />;
    }
    if (currentLayer === MapLayerEnum.Temperature) {
      return <IoThermometerOutline size={24} color={theme.palette.White} />;
    }
    if (currentLayer === MapLayerEnum.Precipitation) {
      return <IoRainyOutline size={20} color={theme.palette.White} />;
    }
  };

  if (!visible) return null;

  return (
    <VBox
      position="absolute"
      width="70px"
      height="50px"
      Top="0px"
      Right="0px"
      Padding="5px"
      gap="10px"
      backgroundColor={theme.palette.White}
      BorderRadius="0px 0 0 20px"
      shadow="small"
      zIndex={1}
    >
      <HBox zIndex={1}>
        <ButtonOpacity onClick={() => setIsExpand(!isExpand)}>
          <HBox gap="5px" alignItems="center">
            <Button
              isCustom
              width="40px"
              height="40px"
              buttonType={ButtonType.Primary}
              padding="5px"
            >
              {getActiveIcon()}
            </Button>
            {isExpand ? (
              <IoChevronUpOutline color={theme.palette.Grey60} />
            ) : (
              <IoChevronDownOutline color={theme.palette.Grey60} />
            )}
          </HBox>
        </ButtonOpacity>
      </HBox>
      <VBox
        visible={isExpand}
        position="absolute"
        Top="60px"
        Right="0px"
        Padding="10px"
        gap="10px"
        backgroundColor={theme.palette.White}
        BorderRadius="20px 0 0 20px"
      >
        <Text
          type={TextTypeEnum.body}
          weight={TextWeightEnum.medium}
          size={TextSizeEnum.medium}
          color={theme.palette.Grey80}
        >
          {getText("Layers.components.Title")}
        </Text>
        <HBox backgroundColor={theme.palette.White} BorderRadius="40px">
          <Button
            width="100%"
            isCustom
            height="40px"
            onClick={() => onChange(MapLayerEnum.Temperature)}
            buttonType={
              currentLayer === MapLayerEnum.Temperature
                ? ButtonType.Primary
                : ButtonType.Ghost
            }
            padding="10px"
          >
            <HBox alignItems="center" gap="5px">
              <IoThermometerOutline
                size={24}
                color={
                  currentLayer === MapLayerEnum.Temperature
                    ? theme.palette.White
                    : theme.palette.Grey70
                }
              />
              <Text
                type={TextTypeEnum.caption}
                weight={TextWeightEnum.regular}
                size={TextSizeEnum.small}
                color={
                  currentLayer === MapLayerEnum.Temperature
                    ? theme.palette.White
                    : theme.palette.Grey50
                }
              >
                {getText("General.Temperature")}
              </Text>
            </HBox>
          </Button>
        </HBox>
        <HBox backgroundColor={theme.palette.White} BorderRadius="40px">
          <Button
            isCustom
            width="100%"
            height="40px"
            onClick={() => onChange(MapLayerEnum.Wind)}
            buttonType={
              currentLayer === MapLayerEnum.Wind
                ? ButtonType.Primary
                : ButtonType.Ghost
            }
            padding="10px"
          >
            <HBox alignItems="center" gap="5px">
              <IoArrowUp
                size={20}
                color={
                  currentLayer === MapLayerEnum.Wind
                    ? theme.palette.White
                    : theme.palette.Grey70
                }
              />
              <Text
                type={TextTypeEnum.caption}
                weight={TextWeightEnum.regular}
                size={TextSizeEnum.small}
                color={
                  currentLayer === MapLayerEnum.Wind
                    ? theme.palette.White
                    : theme.palette.Grey50
                }
              >
                {getText("General.Wind")}
              </Text>
            </HBox>
          </Button>
        </HBox>
        <HBox backgroundColor={theme.palette.White} BorderRadius="40px">
          <Button
            isCustom
            width="100%"
            height="40px"
            onClick={() => onChange(MapLayerEnum.Precipitation)}
            buttonType={
              currentLayer === MapLayerEnum.Precipitation
                ? ButtonType.Primary
                : ButtonType.Ghost
            }
            padding="10px"
          >
            <HBox alignItems="center" gap="5px">
              <IoRainyOutline
                size={20}
                color={
                  currentLayer === MapLayerEnum.Precipitation
                    ? theme.palette.White
                    : theme.palette.Grey70
                }
              />
              <Text
                type={TextTypeEnum.caption}
                weight={TextWeightEnum.regular}
                size={TextSizeEnum.small}
                color={
                  currentLayer === MapLayerEnum.Precipitation
                    ? theme.palette.White
                    : theme.palette.Grey50
                }
              >
                {getText("General.Liquid")}
              </Text>
            </HBox>
          </Button>
        </HBox>
      </VBox>
    </VBox>
  );
};

export default Layers;
