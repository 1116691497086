import { BranchListProps } from "./IBranchList";
import HalfModal from "../../HalfModal/HalfModal";
import ListItem from "../../ListItem/ListItem";
import { VBox } from "../../../styles/Flexbox.styled";
import { getBranches } from "../../../services/DistrictService";
import { getText } from "../../../locales/initI18n";
import { useQuery } from "@tanstack/react-query";

const BranchList: React.FC<BranchListProps> = ({
  isVisible,
  onClose,
  onConfirm,
  districtId,
}) => {
  const queryFn = () => {
    return getBranches(districtId);
  };

  const { data: branches, isLoading } = useQuery({
    queryKey: ["branches", { districtId }],
    queryFn,
    enabled: isVisible,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  return (
    <HalfModal
      isExpander={false}
      heightDivider={1.1}
      visible={isVisible}
      busy={isLoading}
      title={getText("General.Branch")}
      onClose={onClose}
      Padding="0"
      zIndex={2}
    >
      <VBox>
        {branches?.map((branch, index) => (
          <ListItem
            key={`Branch-${branch.id}`}
            title={branch.name}
            description={`${branch.postcode} ${branch.city}`}
            rTitle={branch.branch_number}
            rDescription={branch.street}
            darkest={index % 2 === 0}
            onClick={() => onConfirm(branch)}
          />
        ))}
      </VBox>
    </HalfModal>
  );
};

export default BranchList;
