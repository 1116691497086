import { getText, userLocale } from "../locales/initI18n";

import { DatePatternEum } from "../models/enums/DatePatternEnum";

type DateOrString = Date | string;

export default class DateHelper {
  static DATE_FORMAT_OPTIONS = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  } as const;
  static DATETIME_FORMAT_OPTIONS = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  } as const;

  private static _dateFormat: string | null = null;
  private static _dateTimeFormat: string | null = null;
  private static _dateTimeReadableFormat: string | null = null;

  static toDate(date: DateOrString | undefined): Date {
    if (!date) return new Date();
    if (typeof date === "string") {
      return new Date(date);
    }
    return date;
  }
  static getDiff(start: DateOrString, end: DateOrString): number {
    const diff =
      DateHelper.toDate(end).getTime() - DateHelper.toDate(start).getTime();
    return diff;
  }
  static getISOString(dateOrString: DateOrString) {
    if (!dateOrString) return null;
    const date = DateHelper.toDate(dateOrString);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    const timestamp = Date.UTC(year, month - 1, day, hours, minutes, seconds);

    return new Date(timestamp).toISOString();
  }
  static getFormattedDateTime(dateOrString: DateOrString) {
    if (!dateOrString) return null;
    const date = DateHelper.toDate(dateOrString);
    return date.toLocaleString(
      userLocale as string,
      DateHelper.DATETIME_FORMAT_OPTIONS
    );
  }
  static getFormattedDate(dateOrString: DateOrString) {
    if (!dateOrString) return null;
    const date = DateHelper.toDate(dateOrString);
    return date.toLocaleDateString(
      userLocale as string,
      DateHelper.DATE_FORMAT_OPTIONS
    );
  }
  static getDateFormat() {
    if (DateHelper._dateFormat) return DateHelper._dateFormat;
    const locale = userLocale;
    const options = DateHelper.DATE_FORMAT_OPTIONS;
    const date = new Date(2024, 11, 4); // Using a sample date
    const formattedDate = date.toLocaleDateString(locale, options);
    const formatParts = new Intl.DateTimeFormat(locale, options).formatToParts(
      date
    );

    let formatString = formattedDate;
    formatParts.forEach((part) => {
      switch (part.type) {
        case "day":
          formatString = formatString.replace(part.value, DatePatternEum.day);
          break;
        case "month":
          formatString = formatString.replace(part.value, DatePatternEum.month);
          break;
        case "year":
          formatString = formatString.replace(part.value, DatePatternEum.year);
          break;
      }
    });

    return (DateHelper._dateFormat = formatString);
  }
  static getDateTimeReadableFormat() {
    if (this._dateTimeReadableFormat) return this._dateTimeReadableFormat;
    const format = DateHelper.getDateTimeFormat();
    this._dateTimeReadableFormat = format
      .replace(DatePatternEum.dayPeriod, getText("DateInput.Period"))
      .replace(DatePatternEum.day, getText("DateInput.Day"))
      .replace(DatePatternEum.month, getText("DateInput.Month"))
      .replace(DatePatternEum.year, getText("DateInput.Year"))
      .replace(DatePatternEum.hour12, getText("DateInput.Hour12"))
      .replace(DatePatternEum.hour24, getText("DateInput.Hour24"))
      .replace(DatePatternEum.minute, getText("DateInput.Minute"))
      .replace(DatePatternEum.second, getText("DateInput.Second"));
  }
  static getDateTimeFormat() {
    if (DateHelper._dateTimeFormat) return DateHelper._dateTimeFormat;
    const locale = userLocale as string;
    const options = DateHelper.DATETIME_FORMAT_OPTIONS;
    const date = new Date(2024, 11, 4); // Using a sample date
    const formattedDate = date.toLocaleDateString(locale, options);
    const formatParts = new Intl.DateTimeFormat(locale, options).formatToParts(
      date
    );

    let formatString = formattedDate;
    let hasDayPeriod = formatParts.some((part) => part.type === "dayPeriod");

    formatParts.forEach((part) => {
      switch (part.type) {
        case "day":
          formatString = formatString.replace(part.value, DatePatternEum.day);
          break;
        case "month":
          formatString = formatString.replace(part.value, DatePatternEum.month);
          break;
        case "year":
          formatString = formatString.replace(part.value, DatePatternEum.year);
          break;
        case "hour":
          formatString = formatString.replace(
            part.value,
            hasDayPeriod ? DatePatternEum.hour12 : DatePatternEum.hour24
          );
          break;
        case "minute":
          formatString = formatString.replace(
            part.value,
            DatePatternEum.minute
          );
          break;
        case "second":
          formatString = formatString.replace(
            part.value,
            DatePatternEum.second
          );
          break;
        case "dayPeriod":
          formatString = formatString.replace(
            part.value,
            DatePatternEum.dayPeriod
          );
          break;
      }
    });

    return (DateHelper._dateTimeFormat = formatString);
  }
}
