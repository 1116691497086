import { AxiosRequestConfig } from "axios";
import { AxiosResponse } from "axios";
import CookieHelper from "../utils/CookieHelper";
import axios from "axios";

export const isProduction = () => {
  if (process.env.NODE_ENV === "development") return false;
  return true;
};

const customUrl = "";

const getAPIUrl = () => {
  if (isProduction()) return "https://api.digging.pl/";
  if (customUrl) return customUrl;
  return "http://localhost:3000/";
};

export const API = getAPIUrl();

// export const API = "https://api.digging.pl/";

export const get = async <T = any, R = AxiosResponse<T>, D = any>(
  url: string,
  config?: AxiosRequestConfig<D>,
  isAuth = false
) => {
  const conf = await addConfiguration(config, isAuth);
  return axios.get<T, R, D>(`${API}${url}`, conf);
};

export const authGet = <T = any, R = AxiosResponse<T>, D = any>(
  url: string,
  config?: AxiosRequestConfig<D>
) => {
  return get<T, R, D>(url, config, true);
};

export const post = async <T = any, R = AxiosResponse<T>, D = any>(
  url: string,
  data?: D,
  config?: AxiosRequestConfig<D>,
  isAuth = false
) => {
  const conf = await addConfiguration(config, isAuth);
  return axios.post<T, R, D>(`${API}${url}`, data, conf);
};

export const authPost = async <T = any, R = AxiosResponse<T>, D = any>(
  url: string,
  data?: D,
  config?: AxiosRequestConfig<D>
) => {
  return post<T, R, D>(url, data, config, true);
};

export const put = async <T = any, R = AxiosResponse<T>, D = any>(
  url: string,
  data?: D,
  config?: AxiosRequestConfig<D>,
  isAuth = false
) => {
  const conf = await addConfiguration(config, isAuth);
  return axios.put<T, R, D>(`${API}${url}`, data, conf);
};

export const authPut = async <T = any, R = AxiosResponse<T>, D = any>(
  url: string,
  data?: D,
  config?: AxiosRequestConfig<D>
) => {
  return put<T, R, D>(url, data, config, true);
};

export const patch = async <T = any, R = AxiosResponse<T>, D = any>(
  url: string,
  data?: D,
  config?: AxiosRequestConfig<D>,
  isAuth = false
) => {
  const conf = await addConfiguration(config, isAuth);
  return axios.patch<T, R, D>(`${API}${url}`, data, conf);
};

export const authPatch = async <T = any, R = AxiosResponse<T>, D = any>(
  url: string,
  data?: D,
  config?: AxiosRequestConfig<D>
) => {
  return patch<T, R, D>(url, data, config, true);
};

export const Delete = async <T = any, R = AxiosResponse<T>, D = any>(
  url: string,
  config?: AxiosRequestConfig<D>,
  isAuth = false
) => {
  const conf = await addConfiguration(config, isAuth);
  return axios.delete<T, R, D>(`${API}${url}`, conf);
};

export const authDelete = async <T = any, R = AxiosResponse<T>, D = any>(
  url: string,
  config?: AxiosRequestConfig<D>
) => {
  const conf = await addConfiguration(config, true);
  return axios.delete<T, R, D>(`${API}${url}`, conf);
};

const addConfiguration = async (
  config?: AxiosRequestConfig,
  isAuth = false
) => {
  const token = CookieHelper.token.get();
  const conf = {
    ...config,
    headers: {
      "Content-Type": "application/json",
      ...config?.headers,
    },
  };
  if (isAuth) {
    conf.headers = {
      ...conf.headers,
      Authorization: `Bearer ${token}`,
    };
  }

  return conf;
};
