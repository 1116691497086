import moment from "moment";
import { userLocale } from "../locales/initI18n";

/**
 * @description Fix float number with comma as separator. The function change comma to dot and return string with float number. If the number is not a number, the function return "0".
 * @param float Number in string format with comma as separator.
 * @returns Changing comma to dot and return string with float number. If the number is not a number, the function return "0".
 * @example
 * const float = "1,5";
 * const fixedFloat = fixFloat(float);
 * console.log(fixedFloat); // "1.5"
 * @example
 * const float = "1.5";
 * const fixedFloat = fixFloat(float);
 * console.log(fixedFloat); // "1.5"
 * @example
 * const float = "1,5a";
 * const fixedFloat = fixFloat(float);
 * console.log(fixedFloat); // "0"
 */
export const fixFloat = (float: string | number): string => {
  const fixedFloat = float?.toString().replace(",", ".");
  if (isNaN(Number(fixedFloat))) {
    return "0";
  }
  return fixedFloat;
};

export const isObjEmpty = (obj: object) => {
  if (typeof obj !== "object") return true;
  return Object.keys(obj).length === 0;
};
/**
 * @deprecated Use Formatter
 * @param date
 * @returns
 */
export const formatDate = (date: Date | string | undefined | null) => {
  if (!date) return "";
  return moment(date).format("YYYY-MM-DD");
};
/**
 * @deprecated Use Formatter
 * @param date
 * @returns
 */
export const formatDateTime = (date: Date | string | undefined | null) => {
  if (!date) return "";
  return moment(date).format("YYYY-MM-DD HH:mm");
};

const units = {
  year: 24 * 60 * 60 * 1000 * 365,
  month: (24 * 60 * 60 * 1000 * 365) / 12,
  day: 24 * 60 * 60 * 1000,
  hour: 60 * 60 * 1000,
  minute: 60 * 1000,
  second: 1000,
};

const rtf = new Intl.RelativeTimeFormat(userLocale, { numeric: "auto" });

export const getRelativeTime = (d1: Date, d2 = new Date()) => {
  var elapsed = d1.getTime() - d2.getTime();

  // "Math.abs" accounts for both "past" & "future" scenarios
  for (const u in units) {
    const unit = u as keyof typeof units;
    if (Math.abs(elapsed) > units[unit] || u == "second")
      return rtf.format(Math.round(elapsed / units[unit]), unit);
  }
};
