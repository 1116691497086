import { HBox, VBox } from "../../styles/Flexbox.styled";
import { Handle, NodeProps, NodeToolbar, Position } from "reactflow";
import { PigeonNodeAction, PigeonNodeData } from "./NodeProps";
import { memo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { ButtonOpacity } from "../../styles/ButtonOpacity.styled";
import ConfirmRemoveDependencyParent from "../ConfirmRemoveDependencyParent";
import HighligtedText from "../HighlightedText/HighlightedText";
import Pigeon from "../../models/Pigeon";
import { PigeonSexEnum } from "../../models/enums/PigeonSexEnum";
import Text from "../Text/Text";
import TextSizeEnum from "../../models/enums/TextSizeEnum";
import TextTypeEnum from "../../models/enums/TextTypeEnum";
import TextWeightEnum from "../../models/enums/TextWeightEnum";
import { getText } from "../../locales/initI18n";
import { useTheme } from "styled-components";

export default memo(({ isConnectable, data }: NodeProps<PigeonNodeData>) => {
  const theme = useTheme();
  const pigeon = data.pigeon;
  const navigate = useNavigate();
  const [isDeleteVisible, setIsDeleteVisible] = useState(false);

  const onShow = () => {
    navigate(`/dashboard/pigeon/${pigeon.id}`);
    data.onAction(PigeonNodeAction.Show, pigeon);
  };

  const onRemoveDependency = () => {
    setIsDeleteVisible(false);
    data.onAction(PigeonNodeAction.Remove, pigeon);
  };

  return (
    <VBox
      position="relative"
      Padding="10px"
      BorderRadius="10px"
      border={`1px solid ${theme.palette.Grey10}`}
      backgroundColor={theme.palette.White}
      width="200px"
    >
      <ConfirmRemoveDependencyParent
        pigeon={data.predecessor || new Pigeon()}
        parent={pigeon}
        onClose={() => setIsDeleteVisible(false)}
        onConfirm={onRemoveDependency}
        isVisible={isDeleteVisible}
      />
      <VBox
        position="absolute"
        Top="-10px"
        Right="20px"
        visible={data.level !== 1}
      >
        <HighligtedText
          text={pigeon.sex === PigeonSexEnum.Male ? "Ojciec" : "Matka"}
          backgroundColor={
            pigeon.sex === PigeonSexEnum.Male
              ? theme.palette.Primary40
              : theme.palette.Red40
          }
        />
      </VBox>
      <VBox>
        <Text
          type={TextTypeEnum.body}
          color={theme.palette.Grey70}
          weight={TextWeightEnum.medium}
          size={TextSizeEnum.small}
        >
          {pigeon.ring.number}
        </Text>
        <Text
          type={TextTypeEnum.body}
          color={theme.palette.Grey60}
          weight={TextWeightEnum.regular}
          size={TextSizeEnum.small}
        >
          {pigeon.name}
        </Text>
      </VBox>
      <VBox
        width="100%"
        height="1px"
        backgroundColor={theme.palette.Grey10}
        Margin="5px 0"
      />
      <VBox>
        <Text
          type={TextTypeEnum.caption}
          color={theme.palette.Grey70}
          weight={TextWeightEnum.regular}
          size={TextSizeEnum.small}
        >
          {getText("PigeonNode.PigeonData")}
        </Text>
        <Text
          type={TextTypeEnum.caption}
          color={theme.palette.Grey50}
          weight={TextWeightEnum.regular}
          size={TextSizeEnum.small}
        >
          {getText("General.Color")}: {pigeon.ReadableColor}
        </Text>
        <Text
          type={TextTypeEnum.caption}
          color={theme.palette.Grey50}
          weight={TextWeightEnum.regular}
          size={TextSizeEnum.small}
        >
          {getText("General.Breed")}: {pigeon.breed || "-"}
        </Text>
      </VBox>
      <VBox
        width="100%"
        height="1px"
        backgroundColor={theme.palette.Grey10}
        Margin="5px 0"
      />
      <VBox>
        <Text
          type={TextTypeEnum.caption}
          color={theme.palette.Grey70}
          weight={TextWeightEnum.regular}
          size={TextSizeEnum.small}
        >
          {getText("PigeonNode.AchievementDescription")}
        </Text>
        {pigeon.achievements.map((achievement, index) => {
          if (!achievement.useInPedigree) return null;
          return (
            <>
              <Text
                type={TextTypeEnum.caption}
                color={theme.palette.Grey60}
                weight={TextWeightEnum.medium}
                size={TextSizeEnum.small}
              >
                {achievement.name} ({achievement.year.toString()})
              </Text>
              <Text
                type={TextTypeEnum.caption}
                color={theme.palette.Grey50}
                weight={TextWeightEnum.regular}
                size={TextSizeEnum.small}
              >
                {achievement.remarks}
              </Text>
              <VBox
                visible={index > pigeon.achievements.length - 1}
                Margin="5px"
                width="100%"
                height="1px"
                backgroundColor={theme.palette.Grey10}
              />
            </>
          );
        })}
      </VBox>
      <Handle
        type="target"
        position={Position.Left}
        style={{ background: theme.palette.Grey40 }}
        isConnectable={isConnectable}
      />
      <Handle
        type="source"
        position={Position.Right}
        style={{ background: theme.palette.Grey40 }}
        isConnectable={isConnectable}
      />
      <NodeToolbar position={Position.Bottom}>
        <VBox
          gap="10px"
          Padding="10px"
          backgroundColor={theme.palette.White}
          BorderRadius="10px"
        >
          <Text
            type={TextTypeEnum.footer}
            color={theme.palette.Grey70}
            weight={TextWeightEnum.medium}
            size={TextSizeEnum.medium}
          >
            {pigeon.ring.number} ({pigeon.name})
          </Text>
          <HBox gap="10px">
            <ButtonOpacity onClick={() => onShow()}>
              <VBox
                height="100%"
                backgroundColor={theme.palette.Grey5}
                Padding="5px"
                BorderRadius="10px"
                alignItems="center"
                justifyContent="center"
                border={`1px solid ${theme.palette.Grey60}`}
              >
                <Text
                  Margin="5px 0 0 0"
                  type={TextTypeEnum.footer}
                  color={theme.palette.Grey60}
                  weight={TextWeightEnum.medium}
                  size={TextSizeEnum.medium}
                >
                  {getText("PigeonNode.action.show")}
                </Text>
              </VBox>
            </ButtonOpacity>
            {/* <ButtonOpacity
              onClick={() => data.onAction(PigeonNodeAction.Edit, pigeon)}
            >
              <VBox
                height="100%"
                backgroundColor={theme.palette.Primary10}
                Padding="5px"
                BorderRadius="10px"
                alignItems="center"
                justifyContent="center"
                border={`1px solid ${theme.palette.Primary100}`}
              >
                <Text
                  Margin="5px 0 0 0"
                  type={TextTypeEnum.footer}
                  color={theme.palette.Primary100}
                  weight={TextWeightEnum.medium}
                  size={TextSizeEnum.medium}
                >
                  {getText("PigeonNode.action.edit")}
                </Text>
              </VBox>
            </ButtonOpacity> */}
            <ButtonOpacity onClick={() => setIsDeleteVisible(true)}>
              <VBox
                height="100%"
                backgroundColor={theme.palette.Red10}
                Padding="5px"
                BorderRadius="10px"
                alignItems="center"
                justifyContent="center"
                border={`1px solid ${theme.palette.Red100}`}
              >
                <Text
                  type={TextTypeEnum.footer}
                  color={theme.palette.Red100}
                  weight={TextWeightEnum.medium}
                  size={TextSizeEnum.medium}
                >
                  {getText("PigeonNode.action.remove")}
                </Text>
              </VBox>
            </ButtonOpacity>
          </HBox>
        </VBox>
      </NodeToolbar>
    </VBox>
  );
});
