import Text from "./Text/Text";
import { useTheme } from "styled-components";

const ObjectIdentifier = ({
  title,
  text,
  textAlign = "left",
}: {
  title: string;
  text: string;
  textAlign?: string;
}) => {
  const theme = useTheme();
  return (
    <>
      <Text
        type="body"
        size="medium"
        weight="bold"
        color={theme.palette.Grey100}
        textAlign={textAlign}
      >
        {title}
      </Text>
      <Text
        type="body"
        size="small"
        weight="regular"
        color={theme.palette.Grey70}
        textAlign={textAlign}
      >
        {text}
      </Text>
    </>
  );
};

export default ObjectIdentifier;
