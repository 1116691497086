import "@ui5/webcomponents-react/dist/Assets";
import "@ui5/webcomponents-fiori/dist/Assets";
import "@ui5/webcomponents-icons/dist/Assets";
import "@ui5/webcomponents-icons/dist/AllIcons";
import "@ui5/webcomponents/dist/TableRow";
import "@ui5/webcomponents/dist/TableCell";
import "@ui5/webcomponents/dist/TableHeaderRow";
import "@ui5/webcomponents/dist/TableHeaderCell";
import "@ui5/webcomponents/dist/features/InputSuggestions";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import AppRoutes from "./AppRoutes";
import { HBox } from "./styles/Flexbox.styled";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Provider as ReduxProvider } from "react-redux";
import { ThemeProvider } from "styled-components";
import createTheme from "./styles/theme";
import store from "./redux/store";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { userLocale } from "./locales/initI18n";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 2,
    },
  },
});

function App() {
  const theme = createTheme("light");
  const [_, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (userLocale) {
      setSearchParams({
        "sap-ui-language": userLocale,
      });
    }
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <ReduxProvider store={store}>
        {/* <GoogleOAuthProvider clientId="1071769462356-ojicc6iviontbneppsitbf7vdn80g10m.apps.googleusercontent.com"> */}
        <ThemeProvider theme={theme}>
          <HBox
            width="100%"
            height="100%"
            backgroundColor={theme.palette.White}
          >
            <AppRoutes />
          </HBox>
        </ThemeProvider>
        {/* </GoogleOAuthProvider> */}
      </ReduxProvider>
      <ReactQueryDevtools />
    </QueryClientProvider>
  );
}

export default App;
