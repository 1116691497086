/**
 * TagType enum for ITag interface. TagType is used to determine the type of tag.
 * @export
 * @enum {number}
 * @property {number} Predefined - Predefined tag is only created by the system.
 * @property {number} Private - Private tag is created by the user.
 * @property {number} Global - Global tag is created by the user and shared with other users.
 * @property {number} Temporary - Temporary (Custom, FlightTag) tag is created when user create flight and choose custom latitudd and longitude for the flight (not the other tag).
 */
export enum TagType {
  Predefined,
  Private,
  Global,
  Temporary,
}
