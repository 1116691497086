import { useRef, useState } from "react";

import Debounce from "../utils/Debounce";
import PageOptions from "../models/PageOptions";
import { SuggestionItem } from "@ui5/webcomponents-react";
import TextInput from "./TextInput";
import { getRings } from "../services/RingService";
import { getText } from "../locales/initI18n";

interface RingNumberInputProps {
  [key: string]: any;

  onChange?: (value: string) => void;
}

const RingNumberInput = (props: RingNumberInputProps) => {
  const [ringSuggestions, setRingSuggestions] = useState<string[]>([]);

  const onChangeRing = async (value: string) => {
    if (value.length < 3) {
      setRingSuggestions([]);
      return;
    }
    const options = new PageOptions({
      page: 1,
      take: 5,
      filters: `number=='=LIKE=${value}=LIKE='`,
    });
    const rings = await getRings(options);
    const ringSuggestions = rings.data.map((ring) => ring.number);
    setRingSuggestions(ringSuggestions);
  };

  const ringDebounce = useRef<Debounce>(new Debounce(onChangeRing, 500));
  return (
    <TextInput
      {...props}
      onChange={(value) => {
        ringDebounce.current.exec(value);
        props.onChange?.(value);
      }}
      placeholder="PL-0000-00-0000"
      showSuggestions={true}
      suggestions={ringSuggestions.map((ring) => (
        <SuggestionItem
          key={`RingNumberInput-ringSuggetions-${ring}`}
          text={ring}
        />
      ))}
    >
      {getText("General.RingNumber")}
    </TextInput>
  );
};

export default RingNumberInput;
