import { ReactNode } from "react";

export enum ListItemAction {
  Primary,
  Success,
  Warning,
  Critical,
  Secondary,
  Transparent,
  Checkbox,
}

export interface ListItemProps {
  title: string;
  description: string | number | null | undefined;
  icon?: ReactNode;
  visible?: boolean;
  rVisible?: boolean;
  rTitle?: string;
  rDescription?: string | number | null | undefined;
  rIcon?: ReactNode;
  darkest?: boolean;
  padding?: string;
  margin?: string;
  children?: ReactNode;
  action?: ListItemAction;
  isChecked?: boolean;
  busy?: boolean;
  borderRadius?: string;
  overflowX?: string;
  overflowY?: string;
  onClick?: () => void;
}
