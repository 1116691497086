import {
  InputDomRef,
  SuggestionItem,
  Ui5CustomEvent,
} from "@ui5/webcomponents-react";
import { useRef, useState } from "react";

import Debounce from "../utils/Debounce";
import { IFarmer } from "../services/IFollowService";
import { IFriend } from "./modals/Friends/IFriends";
import ITextInput from "../styles/ITextInput";
import { InputSelectionChangeEventDetail } from "@ui5/webcomponents/dist/Input";
import PageOptions from "../models/PageOptions";
import TextInput from "./TextInput";
import { getFriends } from "../services/FollowerService";

interface FriendsInputProps {
  onChangeOwner?: (owner: IFarmer | undefined) => void;
}

const FriendsInput = (props: ITextInput & FriendsInputProps) => {
  const [friendSuggestions, setFriendSuggestions] = useState<IFriend[]>([]);
  const onChangeOwner = async (value: string) => {
    const options = new PageOptions({
      page: 1,
      take: 5,
      filters: `username=='=LIKE=${value}=LIKE='`,
    });
    const friends = await getFriends(options);
    setFriendSuggestions(friends.data);
  };

  const ownerDebounce = useRef<Debounce>(new Debounce(onChangeOwner, 500));
  return (
    <TextInput
      {...props}
      onSelectionChange={(
        e: Ui5CustomEvent<InputDomRef, InputSelectionChangeEventDetail>
      ) => {
        const item = e.detail.item;
        if (!item) return props.onChangeOwner?.(undefined);
        const userId = item.dataset.userid;
        if (!userId) return props.onChangeOwner?.(undefined);
        const friend = friendSuggestions.find(
          (f) => f.id.toString() === userId
        );
        props.onChangeOwner?.(friend);
        props.onChange?.(friend?.username || "");
      }}
      onChange={(value) => {
        props.onChange?.(value);
        ownerDebounce.current.exec(value);
      }}
      suggestions={friendSuggestions.map((friend) => (
        <SuggestionItem
          key={`AddPigeonForm.friends.${friend.id}`}
          text={friend.username}
          data-userid={friend.id}
        />
      ))}
      showSuggestions={true}
    >
      {props.children}
    </TextInput>
  );
};

export default FriendsInput;
