import { HBox, VBox } from "../../styles/Flexbox.styled";

import HighligtedText from "../HighlightedText/HighlightedText";
import Text from "../Text/Text";
import TextSizeEnum from "../../models/enums/TextSizeEnum";
import TextTypeEnum from "../../models/enums/TextTypeEnum";
import TextWeightEnum from "../../models/enums/TextWeightEnum";
import { getText } from "../../locales/initI18n";
import { useTheme } from "styled-components";

const FirstNews = () => {
  const theme = useTheme();
  return (
    <VBox width="100%">
      <HBox justifyContent="flex-end">
        <HighligtedText
          text={getText("FirstNews.PublicationDate")}
          backgroundColor={theme.palette.Yellow100}
        />
      </HBox>
      <VBox>
        <VBox>
          <Text
            type={TextTypeEnum.h6}
            size={TextSizeEnum.large}
            weight={TextWeightEnum.medium}
            color={theme.palette.Grey90}
          >
            {getText("FirstNews.AvailableInPigeonMapTitle")}
          </Text>
          <Text
            type={TextTypeEnum.body}
            size={TextSizeEnum.medium}
            weight={TextWeightEnum.regular}
            color={theme.palette.Grey70}
            textAlign="justify"
          >
            {getText("FirstNews.AvailableInPigeonMapDescription")}
          </Text>
          <a
            href="https://play.google.com/store/apps/details?id=com.digging.PigeonMap"
            target="_blank"
            rel="noreferrer"
          >
            <VBox alignItems="center">
              <img
                alt="Pigeon Map logo"
                src="/assets/android/logo192.png"
                width="90px"
              />
              <Text
                type={TextTypeEnum.caption}
                size={TextSizeEnum.large}
                weight={TextWeightEnum.regular}
                color={theme.palette.Grey50}
              >
                {getText("FirstNews.AvailableInPigeonMapButton")}
              </Text>
            </VBox>
          </a>
        </VBox>
        <VBox>
          <Text
            type={TextTypeEnum.h6}
            size={TextSizeEnum.large}
            weight={TextWeightEnum.medium}
            color={theme.palette.Grey90}
          >
            {getText("FirstNews.WindyTitle")}
          </Text>
          <Text
            type={TextTypeEnum.body}
            size={TextSizeEnum.medium}
            weight={TextWeightEnum.regular}
            color={theme.palette.Grey70}
            textAlign="justify"
          >
            {getText("FirstNews.WindyDescription")}
          </Text>
        </VBox>
        <VBox>
          <Text
            type={TextTypeEnum.h6}
            size={TextSizeEnum.large}
            weight={TextWeightEnum.medium}
            color={theme.palette.Grey90}
          >
            {getText("FirstNews.TimeSliderTitle")}
          </Text>
          <Text
            type={TextTypeEnum.body}
            size={TextSizeEnum.medium}
            weight={TextWeightEnum.regular}
            color={theme.palette.Grey70}
            textAlign="justify"
          >
            {getText("FirstNews.TimeSliderDescription")}
          </Text>
        </VBox>
        <VBox>
          <Text
            type={TextTypeEnum.h6}
            size={TextSizeEnum.large}
            weight={TextWeightEnum.medium}
            color={theme.palette.Grey90}
          >
            {getText("FirstNews.WeatherLevelsTitle")}
          </Text>
          <Text
            type={TextTypeEnum.body}
            size={TextSizeEnum.medium}
            weight={TextWeightEnum.regular}
            color={theme.palette.Grey70}
            textAlign="justify"
          >
            {getText("FirstNews.WeatherLevelsDescription")}
          </Text>
        </VBox>
        <VBox>
          <Text
            type={TextTypeEnum.h6}
            size={TextSizeEnum.large}
            weight={TextWeightEnum.medium}
            color={theme.palette.Grey90}
          >
            {getText("FirstNews.BugsTitle")}
          </Text>
          <Text
            type={TextTypeEnum.body}
            size={TextSizeEnum.medium}
            weight={TextWeightEnum.regular}
            color={theme.palette.Grey70}
            textAlign="justify"
          >
            {getText("FirstNews.BugsDescripiton")}
          </Text>
        </VBox>
      </VBox>
    </VBox>
  );
};

export default FirstNews;
