import { Dot, LoaderWrapper } from "./loader.styled";

const Loader = () => (
  <LoaderWrapper>
    <Dot />
    <Dot />
    <Dot />
    <Dot />
  </LoaderWrapper>
);

export default Loader;
