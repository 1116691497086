import { Branch } from "./Branch";
import { IUser } from "../services/IUserService";
import { Base } from "./Base";
import { District } from "./District";
import { Flight } from "./Flight";
import { FlightSharing } from "./FlightSharing";
import Opinion from "./Opinion";
import Pigeon from "./Pigeon";
import { ITag } from "./Tag";
import { UserType } from "./enums/UserType";
import { Section } from "./Section";

export default class User extends Base implements IUser {
  username!: string;
  phoneNumber?: string;
  email!: string;
  firstName?: string;
  lastName?: string;
  type: UserType = UserType.Regular;
  country!: string;
  districtId?: number;
  district!: District;
  branch!: Branch;
  section!: Section;
  isActive: boolean = true;
  isEmailConfirmed: boolean = false;
  lastLoginDate?: Date;
  lastTryLoginDate?: Date;
  deletedAt?: Date;
  newsIsReadedAt?: Date;
  flights?: Flight[];
  flightSharing?: FlightSharing[];
  tags?: ITag[];
  opinions?: Opinion[];
  following?: IUser[];
  followers?: IUser[];
  createdPigeons?: Pigeon[];
  ownedPigeons?: Pigeon[];
  plannerRequestValid!: Date;

  fillData(data: object): this {
    super.fillData(data);
    this.phoneNumber = this.phoneNumber || "-";

    return this;
  }

  isAdmin() {
    return this.type === UserType.Admin;
  }

  isPlanner() {
    return this.type === UserType.Planner;
  }

  isRegular() {
    return this.type === UserType.Regular;
  }
}
