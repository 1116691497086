import { Base } from "./Base";
import PageMeta from "./PageMeta";

export default class Page<T> extends Base {
  data!: T[];
  meta!: PageMeta;

  fillData(data: object): this {
    super.fillData(data);
    this.meta = PageMeta.create(this.meta);
    return this;
  }
}
