import { ComponentType, memo } from "react";
import {
  MessageBoxAction,
  TableSelectionDomRef,
} from "@ui5/webcomponents-react";
import {
  addToSelection,
  moveInSelection,
  removeFromSelection,
} from "../../services/PigeonSeasonSelectionService";
import withDialogs, { WithDialogsProps } from "../withDialogs/withDialogs";

import BaseDialogProps from "../../models/BaseDialogProps";
import DialogResult from "../../models/DialogResult";
import PigeonSeasonSelection from "../../models/PigeonSeasonSelection";
import { PigeonSeasonSelectionGroup } from "../../models/enums/PigeonSeasonSelectionGroup";
import PigeonTopSeasonSelectionSelectDialog from "../../components/modals/PigeonTopSeasonSelectionSelectDialog";
import { getText } from "../../locales/initI18n";
import { setError } from "../../redux/modules/error";
import { useAppDispatch } from "../../redux/hooks";
import { useNavigate } from "react-router-dom";
import usePigeonSeasonSelections from "../../hooks/usePigeonSeasonSelections";
import { withPigeonSeasonSelectionHandlerProps } from "./withPigeonSeasonSelectionHandlerProps";
import useMessage from "../../hooks/useMessage";

function withPigeonSeasonSelectionHandler<
  P extends withPigeonSeasonSelectionHandlerProps
>(Component: ComponentType<P>) {
  const WithComponent = (
    props: Omit<P, keyof withPigeonSeasonSelectionHandlerProps> &
      WithDialogsProps
  ) => {
    const navigate = useNavigate();
    const { openMessage } = useMessage();
    const dispatch = useAppDispatch();
    const { invalidate } = usePigeonSeasonSelections();
    const addToSeasonSelection = async (
      selectionOrIds: TableSelectionDomRef | string[],
      year: number,
      isGoToSelection = true
    ) => {
      const ids = getIds(selectionOrIds);
      try {
        for (const id of ids || []) {
          await addToSelection(Number(id), year);
        }
      } catch (e) {
        dispatch(setError(e));
      }
      invalidate();
      if (!isGoToSelection) return;
      const confirm = await openMessage(
        getText("withPigeonSeasonSelectionHandler.AddedToSelection")
      );

      if (confirm === MessageBoxAction.OK) {
        navigate(`./${new Date().getFullYear()}/selections`);
      }
    };

    const moveSelection = async (
      fromId: number,
      group: PigeonSeasonSelectionGroup
    ) => {
      const result = await props.openDialog(
        "PigeonTopSeasonSelectionSelectDialog",
        { group }
      );

      const toSelection = result.getData<PigeonSeasonSelection>();
      if (!toSelection) return;

      try {
        await moveInSelection(fromId, toSelection.id);
        invalidate();
      } catch (e) {
        dispatch(setError(e));
      }
    };

    const removeSelection = async (selection: PigeonSeasonSelection) => {
      const confirm = await openMessage(
        getText("withPigeonSeasonSelectionHandler.ConfirmRemoveFromSelection", {
          ringNumber: selection.pigeon.RingNumber,
        })
      );

      if (confirm !== MessageBoxAction.OK) return;

      try {
        await removeFromSelection(selection.id);
        invalidate();
      } catch (e) {
        dispatch(setError(e));
      }
    };

    const getIds = (selection: TableSelectionDomRef | string[]) => {
      if (Array.isArray(selection)) {
        return selection;
      }
      const selectedPigeonsText = selection?.selected || "";
      const selectedPigeonIds = selectedPigeonsText.split(" ");

      return selectedPigeonIds;
    };
    return (
      <Component
        {...(props as unknown as P)}
        addToSeasonSelection={addToSeasonSelection}
        moveSelection={moveSelection}
        removeSelection={removeSelection}
      />
    );
  };

  const renderDialogs = (
    dialogName: string,
    props: BaseDialogProps,
    onClose: (value: DialogResult) => void
  ) => {
    switch (dialogName) {
      case "PigeonTopSeasonSelectionSelectDialog": {
        const group = (props as { group: PigeonSeasonSelectionGroup }).group;
        return (
          <PigeonTopSeasonSelectionSelectDialog
            group={group}
            isVisible={true}
            onClose={(value) =>
              onClose(
                new DialogResult(
                  value ? MessageBoxAction.OK : MessageBoxAction.Close,
                  value
                )
              )
            }
          />
        );
      }
    }
  };

  return memo(withDialogs(WithComponent, renderDialogs));
}

export default withPigeonSeasonSelectionHandler;
