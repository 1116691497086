import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./state";
import reducers from "./reducers";

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: { ...reducers },
});

// Action creators are generated for each case reducer function
export const { setAuth, resetAuth } = authSlice.actions;

export default authSlice.reducer;
