import { Base } from "./Base";
import { IUser } from "../services/IUserService";

class Opinion extends Base {
  name!: string;
  comment!: string;
  rating: number = 0;
  user!: IUser;
  userId!: number;
}

export default Opinion;
