import { post } from "./BaseService";

export const confirmEmail = async (token: string) => {
  try {
    await post(`auth/email`, { token });
    return true;
  } catch (e) {
    return false;
  }
};

export const resendConfirmationEmail = async (email: string) => {
  try {
    await post(`auth/email/resend`, { email });
    return true;
  } catch (e) {
    return false;
  }
};
