import { useEffect, useRef, useState } from "react";

import { Country } from "../../models/Country";
import { CountryListProps } from "./ICountryList";
import ListItem from "../ListItem/ListItem";
import RegionDetector from "../../locales/RegionDetector";
import TextInput from "../TextInput";
import { VBox } from "../../styles/Flexbox.styled";
import { getText } from "../../locales/initI18n";
import { useTheme } from "styled-components";

const CountryList = ({ onSelect, country, errors }: CountryListProps) => {
  const timeout = useRef<NodeJS.Timeout | null>(null);
  const countryRef = useRef<Country | null>(null);
  const theme = useTheme();
  const [value, setValue] = useState<string>("");
  const [countries, setCountries] = useState<Country[]>([]);
  const containerRef = useRef<HTMLDivElement>(null);

  const onChange = (e: string) => {
    setValue(e);
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
    if (e.length === 0) {
      onSelect({});
      //setCountries([]);
    }
    timeout.current = setTimeout(async () => {
      const countries = RegionDetector.GetCountries(e);
      setCountries(countries);
    }, 500);
  };

  const onOutsideClick = (e: MouseEvent) => {
    let value = "";
    setValue((prev) => {
      value = prev;
      return prev;
    });
    if (
      !containerRef.current ||
      containerRef.current.contains(e.target as Node)
    ) {
      setCountries(RegionDetector.GetCountries(value));
      return;
    }
    setCountries([]);
    if (value && value !== countryRef.current?.name) {
      onSelect({});
      setValue("");
    }
  };

  useEffect(() => {
    window.addEventListener("click", onOutsideClick);
    return () => {
      window.removeEventListener("click", onOutsideClick);
    };
  }, []);

  useEffect(() => {
    countryRef.current = country || null;
    if (country) {
      setValue(country.name);
    }
  }, [country]);

  const select = (country?: Country) => {
    setCountries([]);
    if (country) {
      setValue(country.name);
      onSelect(country);
    }
  };

  return (
    <VBox ref={containerRef} position="relative" flexGrow="1">
      <TextInput
        width="100%"
        value={value}
        onChange={onChange}
        placeholder={getText("CountryList.inputCountryPlaceholder")}
        errors={errors}
        isErrorState={(errors?.length || 0) > 0}
      >
        {getText("CountryList.inputCountryTitle")}
      </TextInput>
      <VBox
        height="400px"
        width="100%"
        visible={countries.length > 0}
        position="absolute"
        Top="50px"
        Margin="5px 0 0 0"
        BorderRadius="20px"
        border={countries.length ? `2px solid ${theme.palette.Grey20}` : ""}
        overflowY="auto"
        backgroundColor={theme.palette.White}
      >
        {countries.map((country, index) => (
          <ListItem
            darkest={index % 2 === 1}
            key={country.code}
            title={getText("CountryList.countryName")}
            description={country.name}
            rTitle={getText("CountryList.countryCode")}
            rDescription={country.code}
            onClick={() => select(country)}
          />
        ))}
      </VBox>
    </VBox>
  );
};

export default CountryList;
