import { Button, ButtonType } from "../styles/Button.styled";
import { HBox, ResponsiveVBox, VBox } from "../styles/Flexbox.styled";
import { TextInputState, TextInputType } from "../styles/ITextInput";
import {
  confirmEmail,
  resendConfirmationEmail,
} from "../services/EmailConfirmationService";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Bar from "../components/Bar/Bar";
import { BarType } from "../components/Bar/IBar";
import Logo from "../components/Logo/Logo";
import { StyledText } from "../styles/Typography.styled";
import TextInput from "../components/TextInput";
import { getText } from "../locales/initI18n";
import { useTheme } from "styled-components";

const EmailConfirmation = () => {
  const { token } = useParams();
  const theme = useTheme();
  const navigate = useNavigate();

  const [email, setEmail] = useState<string>("");

  const [isConfirmed, setIsConfirmed] = useState<boolean | null>(null);
  const [isResend, setIsResend] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const sendEmailConfirmation = async () => {
    const confirmation = token ? await confirmEmail(token) : null;
    setIsConfirmed(confirmation);
  };

  const resendEmailConfirmation = async () => {
    if (!email) return;
    try {
      await resendConfirmationEmail(email);
    } finally {
      setIsDisabled(true);
      setIsResend(true);
    }
  };

  useEffect(() => {
    void sendEmailConfirmation();
  }, [token]);

  return (
    <VBox
      width="100%"
      justifyContent="center"
      alignItems="center"
      backgroundColor={theme.palette.White}
      Padding="10px"
      overflowY="scroll"
    >
      <Logo />
      <ResponsiveVBox
        backgroundColor={theme.palette.White}
        shadow="small"
        RIsShadowSM={false}
        BorderRadius="20px"
        alignItems="center"
        Padding="20px"
        gap="20px"
        maxWidth="480px"
        Margin="0 5px"
      >
        <StyledText
          type="h3"
          weight="medium"
          size="small"
          color={theme.palette.Grey80}
          textAlign="center"
        >
          {getText("AddressEmailConfirmation")}
        </StyledText>
        <Bar type={BarType.Info} visible={isConfirmed === null}>
          {getText("PleaseWaitAMoment")}
        </Bar>
        <Bar type={BarType.Green} visible={isConfirmed === true}>
          {getText("SuccessEmailVerificationText")}
        </Bar>
        <Bar type={BarType.Green} visible={isResend}>
          {getText("ResendEmailSuccessText")}
        </Bar>
        <VBox visible={isConfirmed === false && !isResend} gap="10px">
          <Bar type={BarType.Critical}>
            {getText("ProblemWithConfirmationText")}
          </Bar>
          <HBox width="100%" gap="10px">
            <TextInput
              width="400px"
              inputType={TextInputType.Text}
              state={TextInputState.Default}
              value={email}
              onChange={(e) => setEmail(e)}
              placeholder="jan.kowalski@email.com"
              error=""
            >
              Email
            </TextInput>
          </HBox>
          <Button
            onClick={resendEmailConfirmation}
            buttonType={ButtonType.Primary}
            padding="0 20px"
            disabled={isDisabled}
          >
            {getText("SendLinkAgain")}
          </Button>
        </VBox>
        <Button
          width="100%"
          onClick={() => navigate("/")}
          buttonType={ButtonType.Ghost}
          padding="0 20px"
          disabled={isDisabled}
        >
          {getText("GoToStart")}
        </Button>
      </ResponsiveVBox>
    </VBox>
  );
};

export default EmailConfirmation;
