export default class Debounce {
  private timeout: number | undefined | NodeJS.Timeout;

  constructor(private callback: Function, private delay: number) {}

  public exec = (...args: any[]) => {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    this.timeout = setTimeout(() => {
      this.callback(...args);
    }, this.delay);
  };
}
