import Confirm from "./modals/Confirm/Confirm";
import ConfirmModalTypeEnum from "../models/enums/ConfirmModalTypeEnum";
import { ModalProps } from "./modals/IModalOptions";
import OpinionService from "../services/OpinionService";
import Rate from "./Rate/Rate";
import Text from "./Text/Text";
import TextSizeEnum from "../models/enums/TextSizeEnum";
import TextTypeEnum from "../models/enums/TextTypeEnum";
import TextWeightEnum from "../models/enums/TextWeightEnum";
import { getText } from "../locales/initI18n";
import { useQuery } from "@tanstack/react-query";
import { useTheme } from "styled-components";

const PedigreeInformaion = (props: ModalProps<undefined>) => {
  const theme = useTheme();
  const { data: opinion } = useQuery({
    queryKey: [`opinion`, OpinionService.PEDIGREE_OPINION],
    queryFn: () => OpinionService.getByName(OpinionService.PEDIGREE_OPINION),
  });
  return (
    <Confirm
      type={ConfirmModalTypeEnum.Change}
      onConfirm={() => props.onConfirm(undefined)}
      confirmText={getText("PedigreeInformation.modal.confirm")}
      onClose={() => props.onConfirm(undefined)}
      title={getText("PedigreeInformation.modal.title")}
      isCancel={false}
      isVisible={props.isVisible}
    >
      <Text
        type={TextTypeEnum.body}
        weight={TextWeightEnum.regular}
        size={TextSizeEnum.medium}
      >
        <Text weight={TextWeightEnum.medium} type="h5">
          {getText("PedigreeInformation.title")}
        </Text>
        <br />
        {getText("PedigreeInformation.description")}
        <br />
        <br />
        <Text
          visible={!opinion}
          weight={TextWeightEnum.medium}
          size={TextSizeEnum.large}
        >
          {getText("PedigreeInformation.rate.title")}
        </Text>
        <br />
        <Text visible={!opinion}>
          {getText("PedigreeInformation.rate.description")}
        </Text>
        <br />
        <br />
        <Rate name={OpinionService.PEDIGREE_OPINION} />
      </Text>
    </Confirm>
  );
};

export default PedigreeInformaion;
