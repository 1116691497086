import { Base } from "./Base";
import { District } from "./District";
import { DistrictType } from "./enums/DistrictType";
import { Section } from "./Section";

export class Branch extends Base {
  name!: string;
  branch_number!: string;
  district!: Partial<District>;
  districtId!: number;
  sections: Section[] = [];
  postcode!: string;
  city!: string;
  street!: string;
  phone!: string;
  type!: DistrictType;

  override fillData(data: object): this {
    super.fillData(data);
    this.sections = this.sections?.map(
      Section.create.bind(Section)
    ) as Section[];

    return this;
  }
}
