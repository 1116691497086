export class Country {
  public code: string;
  public name: string;

  constructor(code: string, name: string) {
    this.code = code;
    this.name = name;
  }

  Contains(search: string) {
    return this.name.toUpperCase().includes(search.toUpperCase());
  }

  Equal(search: string): boolean;
  Equal(country: Country): boolean;
  Equal(search: string | Country) {
    if (typeof search === "string") return this.name === search;
    return this.code === search.code;
  }
}
