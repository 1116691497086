import {
  getAssignment,
  getAssignmentByYear,
} from "../services/PigeonSeasonAssignmentService";
import { useQuery, useQueryClient } from "@tanstack/react-query";

import Pigeon from "../models/Pigeon";
import PigeonSeasonAssignment from "../models/PigeonSeasonAssignment";
import { useEffect } from "react";
import { useHookstate } from "@hookstate/core";

const usePigeonAssignment = (pigeon: Pigeon, year: string) => {
  const queryClient = useQueryClient();
  const state = useHookstate({ assignment: new PigeonSeasonAssignment() });
  const getPigeonInternal = async () => {
    const pigeonAssignment = await getAssignmentByYear(pigeon, year);
    state.assignment.set(pigeonAssignment);
    return pigeonAssignment;
  };
  const query = useQuery(
    ["pigeon-season-assignment", year, pigeon?.id],
    getPigeonInternal
  );

  const assignment = state.get({ noproxy: true })
    .assignment as PigeonSeasonAssignment;

  useEffect(() => {
    if (
      (assignment?.pigeonId !== pigeon?.id ||
        assignment?.season?.year !== Number(year)) &&
      query.isFetched &&
      query.data
    ) {
      state.assignment.set(query.data);
    }
  }, [query.data, assignment.id, query.isFetched]);

  return {
    assignment: assignment,
    setter: state.assignment,
    refetch: query.refetch,
  };
};

export default usePigeonAssignment;
