import { Edge, Node, Position } from "reactflow";

import Pigeon from "./Pigeon";
import { PigeonNodeAction } from "../components/Graph/NodeProps";
import { PigeonNodeData } from "./../components/Graph/NodeProps";
import dagre from "@dagrejs/dagre";

export default class GraphData {
  private static dagre: dagre.graphlib.Graph;
  private pigeon!: Pigeon;
  private nodeHeight = 100;
  private nodeWidth = 200;
  private numberOfEdges = 0;
  private maxLevel = 2;
  private _actionCallback = (type: PigeonNodeAction, pigeon: Pigeon) => {};
  nodes!: Node[];
  edges!: Edge[];

  init(pigeon: Pigeon, maxLevel = 2) {
    this.pigeon = pigeon;
    this.maxLevel = maxLevel;
    this.nodes = [];
    this.edges = [];
  }

  public setActionCallback = (
    callback: (type: PigeonNodeAction, pigeon: Pigeon) => void
  ) => {
    this._actionCallback = callback;
  };

  public resetGraph = () => {
    this.nodes = [];
    this.edges = [];

    GraphData.dagre = new dagre.graphlib.Graph();
    GraphData.dagre.setDefaultEdgeLabel(() => ({}));
  };

  public build = async () => {
    this.resetGraph();
    GraphData.dagre.setGraph({ rankdir: "LR" });

    if (!this.pigeon)
      throw new Error("Pigeon is not set. Use init method first.");

    const { nodes, edges } = this.createNodes(this.pigeon);
    this.nodes = nodes;
    this.edges = edges;

    this.nodes.forEach((node) => {
      GraphData.dagre.setNode(node.id, {
        width: this.nodeWidth,
        height: this.getHeight(node),
      });
    });

    this.edges.forEach((edge) => {
      GraphData.dagre.setEdge(edge.source, edge.target);
    });

    dagre.layout(GraphData.dagre);

    this.nodes.forEach((node) => {
      const dagreNode = GraphData.dagre.node(node.id);
      node.position = { x: dagreNode.x, y: dagreNode.y };
    });

    return { nodes: this.nodes, edges: this.edges };
  };

  private getHeight(node: Node) {
    if (node.type === "PigeonNode") {
      const pigeon = (node.data as PigeonNodeData).pigeon;
      const additionalWidth = pigeon.achievements.reduce((prev, curr) => {
        return prev + Math.ceil(curr.remarks.length / 60) * 20 + 10;
      }, 0);
      return this.nodeHeight + additionalWidth;
    }

    return this.nodeHeight;
  }

  protected createNodes = (
    pigeon: Pigeon,
    parent?: Pigeon | null,
    level: number = 1
  ) => {
    const nodes: Node[] = [];
    const edges: Edge[] = [];
    const node = this.createPigeonNode(pigeon, level, parent);
    const edge = this.createEdgePigeon(pigeon, level, parent);
    nodes.push(node);
    if (edge) edges.push(edge);
    if (pigeon.father) {
      const fatherGraph = this.createNodes(pigeon.father, pigeon, level + 1);
      nodes.push(...fatherGraph.nodes);
      edges.push(...fatherGraph.edges);
    } else if (level <= this.maxLevel) {
      const edge = this.createEmptyEdge(pigeon, level, true);
      const emptyNode = this.createEmptyNode(pigeon, level, true);
      nodes.push(emptyNode);
      if (edge) edges.push(edge);
    }

    if (pigeon.mother) {
      const motherGraph = this.createNodes(pigeon.mother, pigeon, level + 1);
      nodes.push(...motherGraph.nodes);
      edges.push(...motherGraph.edges);
    } else if (level <= this.maxLevel) {
      const edge = this.createEmptyEdge(pigeon, level, false);
      const emptyNode = this.createEmptyNode(pigeon, level, false);
      nodes.push(emptyNode);
      if (edge) edges.push(edge);
    }

    return { nodes, edges };
  };

  protected createPigeonNode = (
    pigeon: Pigeon,
    level: number,
    predecessor?: Pigeon | null
  ) => {
    const id = `${pigeon.id.toString()}:${level}`;
    return this.createNode(id, {
      pigeon,
      predecessor,
      level,
      onAction: this._actionCallback,
    });
  };

  protected createEmptyNode = (
    pigeon: Pigeon,
    level: number,
    isFather: boolean
  ) => {
    const id = `${pigeon.id.toString()}:${level}:${
      isFather ? "father" : "mother"
    }`;
    return this.createNode(id, { pigeon, level, isFather }, "EmptyNode");
  };

  private createNode = (id: string, data: object, type = "PigeonNode") => {
    return {
      id,
      position: { x: 0, y: 0 },
      data,
      type,
      sourcePosition: Position.Left,
      targetPosition: Position.Right,
    } as Node;
  };

  protected createEmptyEdge = (
    pigeon: Pigeon,
    level: number,
    isFather: boolean
  ) => {
    this.numberOfEdges++;
    const target = `${pigeon.id.toString()}:${level}:${
      isFather ? "father" : "mother"
    }`;
    const id = `${pigeon.id.toString()}:${level}:${this.numberOfEdges}`;
    return {
      id,
      source: `${pigeon.id.toString()}:${level}`,
      target,
      animated: true,
    } as Edge;
  };

  protected createEdgePigeon = (
    current: Pigeon,
    level: number,
    parent?: Pigeon | null
  ) => {
    if (!parent) return null;
    this.numberOfEdges++;
    return {
      id: `${parent.id.toString()}:${current.id.toString()}:${
        this.numberOfEdges
      }`,
      source: `${parent.id.toString()}:${level - 1}`,
      target: `${current.id.toString()}:${level}`,
      animated: true,
    } as Edge;
  };
}
