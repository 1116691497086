import {
  Table,
  TableCell,
  TableGrowing,
  TableHeaderCell,
  TableHeaderRow,
  TableRow,
} from "@ui5/webcomponents-react";

import Formatter from "../utils/Formatter";
import PageOptions from "../models/PageOptions";
import { getText } from "../locales/initI18n";
import { useNavigate } from "react-router-dom";
import usePigeons from "../hooks/usePigeons";

export interface PigeonSeasonChickTableProps {
  choosenSeason?: null | number;
  interactiveRow?: boolean;
}
const PigeonSeasonChickTable = ({
  choosenSeason,
  interactiveRow,
}: PigeonSeasonChickTableProps) => {
  const navigate = useNavigate();
  const { pigeons, lastMeta, fetchNextPage, busy } = usePigeons(
    new PageOptions({
      filters: `ring.id == null && year == ${choosenSeason}`,
      relations:
        "seasonAssignments,seasonAssignments.season,ring,egg,egg.pigeonPair,egg.pigeonPair.male,egg.pigeonPair.female,egg.pigeonPair.male.ring,egg.pigeonPair.female.ring",
    })
  );

  return (
    <Table
      loading={busy}
      overflowMode="Popin"
      headerRow={[
        <TableHeaderRow>
          <TableHeaderCell>{getText("General.Male")}</TableHeaderCell>
          <TableHeaderCell>{getText("General.Female")}</TableHeaderCell>
          <TableHeaderCell>{getText("General.HatchingDate")}</TableHeaderCell>
        </TableHeaderRow>,
      ]}
      onRowClick={(event) => {
        const key = event.detail.row.rowKey;
        navigate(`/dashboard/pigeon/${key}`);
      }}
    >
      {lastMeta?.isNext && (
        <TableGrowing
          type="Button"
          growing-text={getText("General.More")}
          slot="features"
          onLoadMore={() => fetchNextPage()}
        ></TableGrowing>
      )}
      {pigeons?.map((pigeon) => {
        if (!pigeon.egg) return null;
        return (
          <TableRow
            interactive
            rowKey={pigeon.id.toString()}
            id={pigeon.id.toString()}
            key={`PigoenSeasonChickTable-${pigeon.id}`}
          >
            <TableCell>{pigeon.egg.pigeonPair.maleRingNumber}</TableCell>
            <TableCell>{pigeon.egg.pigeonPair.femaleRingNumber}</TableCell>
            <TableCell>{Formatter.DateTime(pigeon.hatchingDate)}</TableCell>
          </TableRow>
        );
      })}
    </Table>
  );
};

export default PigeonSeasonChickTable;
