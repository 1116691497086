import {
  ListDomRef,
  ListItemStandard,
  ListItemStandardDomRef,
  SelectDialog,
  Ui5CustomEvent,
} from "@ui5/webcomponents-react";
import { useEffect, useState } from "react";

import ListGrowingMode from "@ui5/webcomponents/dist/types/ListGrowingMode";
import ListSelectionMode from "@ui5/webcomponents/dist/types/ListSelectionMode";
import PageOptions from "../../models/PageOptions";
import Pigeon from "../../models/Pigeon";
import PigeonSeasonSelection from "../../models/PigeonSeasonSelection";
import { PigeonSeasonSelectionGroup } from "../../models/enums/PigeonSeasonSelectionGroup";
import usePigeonSeasonSelections from "../../hooks/usePigeonSeasonSelections";
import usePigeons from "../../hooks/usePigeons";

export interface PigeonTopSeasonSelectionSelectDialogProps {
  initFilters?: string;
  isVisible: boolean;
  group: PigeonSeasonSelectionGroup;
  onClose: (value?: PigeonSeasonSelection) => void;
}

const PigeonTopSeasonSelectionSelectDialog = ({
  onClose,
  isVisible = false,
  group,
  initFilters = "",
}: PigeonTopSeasonSelectionSelectDialogProps) => {
  const [filters, setFilters] = useState<string>(initFilters);
  const { selections, refetch } = usePigeonSeasonSelections(
    new PageOptions({
      filters: `group=='${group}'${filters ? "&&" : ""}${filters}`,
      take: 50,
    })
  );

  const getObject = (id: string) => {
    return selections.find((f) => f.id.toString() === id);
  };

  useEffect(() => {
    refetch();
  }, []);

  return (
    <SelectDialog
      open={isVisible}
      onSearch={(event: Ui5CustomEvent<unknown, { value: string }>) => {
        const value = event.detail.value;
        if (value === "") return setFilters("");
        if (!value) return;
        setFilters(`pigeon.ring.number=='=LIKE=${value}=LIKE='`);
      }}
      headerText="Którego gołębia chcesz wymienić?"
      selectionMode={ListSelectionMode.Single}
      onConfirm={(
        event: Ui5CustomEvent<
          ListDomRef,
          { selectedItems: ListItemStandardDomRef[] }
        >
      ) => {
        const item = event.detail.selectedItems[0];
        const id = item.dataset.id;
        if (!id) return onClose();
        const object = getObject(id);
        onClose(object);
      }}
      onClose={() => onClose()}
      onCancel={() => onClose()}
    >
      {selections?.map((item) => (
        <ListItemStandard
          key={`PigeonTopSeasonSelectionSelectDialog-${item.id}`}
          data-id={item.id}
        >
          {item.pigeon.ring.number}
        </ListItemStandard>
      ))}
    </SelectDialog>
  );
};

export default PigeonTopSeasonSelectionSelectDialog;
