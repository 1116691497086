import { CityLocalization } from "../models/CityLocalization";
import { Marker } from "../models/Marker";
import { Weather } from "../models/Weather";
import { authGet } from "./BaseService";

export const getCityLocalization = async (city: string) => {
  const data = await authGet<CityLocalization[]>(
    `weather/localizations/${city}`
  );
  return data.data;
};

export const getWeather = async (
  point: Marker,
  date: Date = new Date()
): Promise<Weather> => {
  const parsedDate = new Date(date);
  const data = await authGet<Weather>(
    `weather/${point.latitude}/${point.longitude}/${parsedDate.toISOString()}`
  );
  return data.data;
};

export const getSummaryWeather = async (flightId: number): Promise<Weather> => {
  const data = await authGet<Weather>(`weather/summary/${flightId}`);
  return data.data;
};
