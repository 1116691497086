import { Base } from "./Base";
import { Branch } from "./Branch";
import { DistrictType } from "./enums/DistrictType";

export class Section extends Base {
  name!: string;
  branch!: Partial<Branch>;
  branchId!: number;
  type!: DistrictType;
}
