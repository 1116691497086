import { Button, ButtonType } from "../../styles/Button.styled";
import { HBox, VBox } from "../../styles/Flexbox.styled";

import Bar from "../Bar/Bar";
import { BarType } from "../Bar/IBar";
import { IoStar } from "react-icons/io5";
import Opinion from "../../models/Opinion";
import OpinionService from "../../services/OpinionService";
import { RateProps } from "./IRate";
import Text from "../Text/Text";
import TextInput from "../TextInput";
import { TextInputType } from "../../styles/ITextInput";
import TextSizeEnum from "../../models/enums/TextSizeEnum";
import TextTypeEnum from "../../models/enums/TextTypeEnum";
import TextWeightEnum from "../../models/enums/TextWeightEnum";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useTheme } from "styled-components";

const Rate = (props: RateProps) => {
  const theme = useTheme();
  const [rate, setRate] = useState(0);
  const [comment, setComment] = useState("");
  const [afterRate, setAfterRate] = useState(false);

  const getOpinion = () => {
    return OpinionService.getByName(props.name);
  };

  const { data: opinion, refetch } = useQuery({
    queryKey: [`opinion`, props.name],
    queryFn: () => getOpinion(),
  });

  const onRate = async () => {
    if (rate === 0) return;
    if (props.onRate) {
      props.onRate(props.name, rate, comment);
    }
    const newOpinion = new Opinion();
    newOpinion.name = props.name;
    newOpinion.rating = rate;
    newOpinion.comment = comment;
    await OpinionService.add(newOpinion);
    setAfterRate(true);
    refetch();
  };

  return (
    <VBox gap="10px" visible={!opinion || afterRate}>
      <HBox gap="10px" justifyContent="center">
        <Button
          onClick={() => setRate(1)}
          buttonType={ButtonType.Ghost}
          isCustom={true}
          height="30px"
        >
          <IoStar
            size={20}
            color={rate >= 1 ? theme.palette.Yellow100 : theme.palette.Grey50}
          />
        </Button>
        <Button
          onClick={() => setRate(2)}
          buttonType={ButtonType.Ghost}
          isCustom={true}
          height="30px"
        >
          <IoStar
            size={20}
            color={rate >= 2 ? theme.palette.Yellow100 : theme.palette.Grey50}
          />
        </Button>
        <Button
          onClick={() => setRate(3)}
          buttonType={ButtonType.Ghost}
          isCustom={true}
          height="30px"
        >
          <IoStar
            size={20}
            color={rate >= 3 ? theme.palette.Yellow100 : theme.palette.Grey50}
          />
        </Button>
        <Button
          onClick={() => setRate(4)}
          buttonType={ButtonType.Ghost}
          isCustom={true}
          height="30px"
        >
          <IoStar
            size={20}
            color={rate >= 4 ? theme.palette.Yellow100 : theme.palette.Grey50}
          />
        </Button>
        <Button
          onClick={() => setRate(5)}
          buttonType={ButtonType.Ghost}
          isCustom={true}
          height="30px"
        >
          <IoStar
            size={20}
            color={rate >= 5 ? theme.palette.Yellow100 : theme.palette.Grey50}
          />
        </Button>
      </HBox>
      <HBox>
        <TextInput
          inputType={TextInputType.TextArea}
          placeholder="Super pomysł!"
          onChange={(e) => setComment(e)}
          value={comment}
        >
          Opinia (opcjonalnie)
        </TextInput>
      </HBox>
      <HBox justifyContent="flex-end">
        <Button
          disabled={afterRate}
          padding="0 10px"
          isCustom
          height="30px"
          buttonType={ButtonType.Primary}
          onClick={onRate}
        >
          <Text
            weight={TextWeightEnum.regular}
            type={TextTypeEnum.body}
            size={TextSizeEnum.medium}
          >
            Wystaw opinię
          </Text>
        </Button>
      </HBox>
      <HBox visible={afterRate}>
        <Bar title="Dziękujemy za opinię" type={BarType.Green}>
          <Text
            weight={TextWeightEnum.regular}
            type={TextTypeEnum.body}
            size={TextSizeEnum.medium}
          >
            Twoja opinia została zapisana. Dziękujemy za poświęcony czas!
          </Text>
        </Bar>
      </HBox>
    </VBox>
  );
};

export default Rate;
