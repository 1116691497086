import { IEditProfile, IUser } from "./IUserService";
import { authGet, authPatch, authPost } from "./BaseService";

import { Branch } from "../models/Branch";
import { District } from "../models/District";
import { Section } from "../models/Section";
import UsersStatistics from "../models/UsersStatistics";
import UserStatistics from "../models/UserStatistics";
import User from "../models/User";

export const getUserData = async () => {
  const result = await authGet<User>("users/profile");

  return User.create(result.data);
};

export const getUsers = () => {
  return authGet<IUser[]>("users");
};

export const editUserData = (data: IEditProfile) => {
  data.phoneNumber = data.phoneNumber || undefined;
  return authPatch<IEditProfile>("users/profile", data);
};

export const fillDistrict = (
  district: District | null,
  branch: Branch | null,
  section: Section | null
) => {
  if (!district || !branch || !section) return;

  const body = {
    districtId: district.id,
    branchId: branch.id,
    sectionId: section.id,
  };
  return authPatch(`users/fill-district`, body);
};

export const acceptPlanner = (token: string) => {
  return authPatch(`users/accept-planner`, { token });
};

export const requestPlanner = () => {
  return authPost(`users/planner-request`);
};

export const isNewsReaded = () => {
  return authPost("users/news-read");
};

export const getUsersStatistics = async () => {
  const result = await authGet<object>("users/statistics");
  return UsersStatistics.create(result.data);
};

export const getUserStatistics = async (userId: number) => {
  const result = await authGet<object>(`users/user/${userId}`);
  const user = UserStatistics.create(result.data);
  user.totalFlights = user.flights?.length || 0;
  user.totalOpinions = user.opinions?.length || 0;
  user.setTags(user.tags);
  return user;
};
