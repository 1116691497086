import { HBox, VBox } from "../../styles/Flexbox.styled";

import { LogoProps } from "./ILogo";
import { ReactNode } from "react";
import Text from "../Text/Text";
import TextSizeEnum from "../../models/enums/TextSizeEnum";
import TextTypeEnum from "../../models/enums/TextTypeEnum";
import TextWeightEnum from "../../models/enums/TextWeightEnum";
import { useTheme } from "styled-components";

const Logo = ({
  imgOnly = false,
  textOnly = false,
  small = false,
  horizontal = false,
}: LogoProps) => {
  const theme = useTheme();
  const getBlock = (children: ReactNode) => {
    if (horizontal) return <HBox>{children}</HBox>;
    return <VBox alignItems="center">{children}</VBox>;
  };

  return getBlock(
    <>
      {!textOnly && (
        <img
          alt="Pigeon Map logo"
          src="/assets/android/logo192.png"
          width={small ? "60px" : "120px"}
          height={small ? "60px" : "120px"}
        />
      )}
      <Text
        visible={!imgOnly}
        color={theme.palette.Grey80}
        type={small ? TextTypeEnum.h3 : TextTypeEnum.h2}
        weight={TextWeightEnum.bold}
        size={small ? TextSizeEnum.small : TextSizeEnum.large}
        Margin="0 0 20px 0"
      >
        Pigeon Map
      </Text>
    </>
  );
};

export default Logo;
