import {
  Button,
  DynamicPage,
  DynamicPageHeader,
  DynamicPageTitle,
  Table,
  TableCell,
  TableGrowing,
  TableHeaderCell,
  TableHeaderRow,
  TableRow,
  TableSelection,
  TableSelectionDomRef,
  Text,
  Title,
  Toolbar,
  ToolbarButton,
} from "@ui5/webcomponents-react";
import { memo, useEffect, useRef } from "react";

import { HBox } from "../styles/Flexbox.styled";
import PageOptions from "../models/PageOptions";
import Pigeon from "../models/Pigeon";
import { PigeonAssignMode } from "../models/enums/PigeonAssignMode";
import TextInput from "./TextInput";
import { breakpoints } from "../styles/Devices";
import { getText } from "../locales/initI18n";
import { getUnassignedPigeons } from "../services/PigeonSeasonAssignmentService";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useTheme } from "styled-components";

export interface PigeonAssignTableProps {
  choosenSeason?: number | null;
  assignMode: PigeonAssignMode;
  slot?: string;
  onClose?: () => void;
  onAssign?: (pigeons: Pigeon[]) => Promise<void>;
}
const options = new PageOptions();
const PigeonAssignPage = ({
  slot,
  choosenSeason,
  onClose,
  onAssign,
  assignMode,
}: PigeonAssignTableProps) => {
  const theme = useTheme();
  const selection = useRef<TableSelectionDomRef>(null);
  const { data, refetch, fetchNextPage } = useInfiniteQuery({
    queryKey: ["pigeons", "unassigned", choosenSeason],
    queryFn: () => getUnassignedPigeons(options, choosenSeason),
    getNextPageParam: options.nextPagefn,
  });
  const onAssignPress = async () => {
    if (!onAssign) return;
    const selectedPigeonsText = selection.current?.selected || "";
    const selectedPigeons = selectedPigeonsText.split(" ");
    const pigeonsToAssign =
      pigeons?.filter((p) => selectedPigeons.includes(p.id.toString())) || [];

    await onAssign?.(pigeonsToAssign);

    refetch();
  };

  useEffect(() => {
    refetch();
  }, []);

  const getHeading = () => {
    if (!choosenSeason) return "";
    return getText("PigeonAssignPage.AssignPigeonsToSeason", {
      season: choosenSeason,
    });
  };

  const getDescription = () => {
    return getText("PigeonAssignPage.SelectPigeonsFromList");
  };

  const pigeons = data?.pages.flatMap((page) => page.data) || [];
  const lastMeta = data?.pages[data.pages.length - 1].meta;

  return (
    <>
      <DynamicPage
        slot={slot}
        style={{
          width: "100%",
        }}
        headerArea={
          <DynamicPageHeader>
            <HBox width="300px">
              <TextInput placeholder="Torun" width="100%">
                {getText("FlightList.Search")}
              </TextInput>
            </HBox>
          </DynamicPageHeader>
        }
        titleArea={
          <DynamicPageTitle
            actionsBar={<Toolbar design="Transparent"></Toolbar>}
            heading={<Title>{getHeading()}</Title>}
            snappedHeading={<Title>{getHeading()}</Title>}
            subheading={<Text>{getDescription()}</Text>}
            snappedSubheading={<Text>{getDescription()}</Text>}
            navigationBar={
              <Toolbar design="Transparent">
                <ToolbarButton
                  design="Transparent"
                  icon="decline"
                  onClick={onClose}
                />
              </Toolbar>
            }
          ></DynamicPageTitle>
        }
      >
        <HBox
          Padding="5px"
          justifyContent="space-between"
          backgroundColor={theme.palette.White}
        >
          <Button icon="response" design="Emphasized" onClick={onAssignPress}>
            {getText("PigeonAssignPage.AddToSeason", { year: choosenSeason })}
          </Button>
        </HBox>
        <Table
          headerRow={
            <TableHeaderRow>
              <TableHeaderCell>{getText("General.RingNumber")}</TableHeaderCell>
              {!breakpoints.isPhone() && (
                <TableHeaderCell>
                  {getText("General.PigeonName")}
                </TableHeaderCell>
              )}
            </TableHeaderRow>
          }
        >
          <TableSelection
            ref={selection}
            mode="Multiple"
            slot="features"
          ></TableSelection>
          {lastMeta?.isNext && (
            <TableGrowing
              type="Button"
              growing-text={getText("General.More")}
              slot="features"
              onLoadMore={() => fetchNextPage()}
            ></TableGrowing>
          )}
          {pigeons?.map((pigeon) => (
            <TableRow
              rowKey={pigeon.id.toString()}
              id={pigeon.id.toString()}
              key={`PigeonAssignPage-${pigeon.id}`}
            >
              <TableCell>{pigeon.RingNumber}</TableCell>
              {!breakpoints.isPhone() && (
                <TableCell>{pigeon.ReadableName}</TableCell>
              )}
            </TableRow>
          ))}
        </Table>
      </DynamicPage>
    </>
  );
};

export default memo(PigeonAssignPage, (prev, next) => {
  return (
    prev.choosenSeason === next.choosenSeason &&
    prev.assignMode === next.assignMode
  );
});
