import {
  Button,
  Form,
  FormGroup,
  FormItem,
  Label,
  Text,
} from "@ui5/webcomponents-react";

import DateInput from "../DateInput/DateInput";
import Egg from "../../models/Egg";
import { EggState } from "../../models/enums/EggStateEnum";
import Formatter from "../../utils/Formatter";
import HalfModal from "../HalfModal/HalfModal";
import { ModalProps } from "./IModalOptions";
import SelectOption from "../../models/SelectOption";
import { getText } from "../../locales/initI18n";
import { useState } from "react";

const options = Object.keys(EggState).map(
  (key) => new SelectOption({ key: key, text: key })
);

interface HatchEggProps extends ModalProps<{ hatchDate: Date }, undefined> {
  egg: Egg;
}

const HatchEgg = (props: HatchEggProps) => {
  const [hatchDate, setHatchDate] = useState<Date>(new Date());

  const onAdd = () => {
    props.onConfirm({ hatchDate });
  };

  return (
    <HalfModal
      visible={props.isVisible}
      onClose={props.onClose}
      title={getText("HatchEgg.Title")}
      description={getText("HatchEgg.Description")}
      isExpander={false}
      heightDivider={1.4}
    >
      <Form>
        <FormGroup>
          <FormItem
            labelContent={<Label>{getText("HatchEgg.DateOfLaying")}</Label>}
          >
            <Text>{Formatter.DateTime(props.egg.dateOfLaying)}</Text>
          </FormItem>
          <FormItem
            labelContent={<Label>{getText("HatchEgg.HatchDate")}</Label>}
          >
            <DateInput
              value={hatchDate}
              onChange={(value) => setHatchDate(value)}
            />
          </FormItem>
          <FormItem>
            <Button onClick={onAdd} design="Emphasized">
              {getText("HatchEgg.HatchEgg")}
            </Button>
          </FormItem>
        </FormGroup>
      </Form>
    </HalfModal>
  );
};

export default HatchEgg;
