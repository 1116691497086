import { HBox, VBox } from "../styles/Flexbox.styled";
import { useEffect, useRef, useState } from "react";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import ChildFilter from "../models/Filter/ChildFilter";
import Debounce from "../utils/Debounce";
import Farmer from "../components/modals/Farmer/Farmer";
import { FilterName } from "../models/enums/FilterName";
import HighligtedText from "../components/HighlightedText/HighlightedText";
import { IFarmer } from "../services/IFollowService";
import InfiniteScroll from "react-infinite-scroll-component";
import ListItem from "../components/ListItem/ListItem";
import Loader from "../components/Loader/loader";
import PageOptions from "../models/PageOptions";
import RegionDetector from "../locales/RegionDetector";
import ShowMore from "../components/ShowMore";
import Text from "../components/Text/Text";
import TextInput from "../components/TextInput";
import { UserType } from "../models/enums/UserType";
import { getAllFarmers } from "../services/FollowerService";
import { getText } from "../locales/initI18n";
import useFilter from "../hooks/useFilter";
import useStatistics from "../hooks/useStatistics";
import { useTheme } from "styled-components";
import withNavbar from "../hoc/withNavbar/withNavbar";

const Farmers = () => {
  const theme = useTheme();
  const { username } = useParams();
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState<string>("");
  const [currentFarmer, setCurrentFarmer] = useState<IFarmer>();
  const [raw, setFilter] = useFilter();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isMore, setIsMore] = useState(false);

  // const getAll = () => {
  //   return getAllFarmers(options.setFilters(raw));
  // };

  const { data, fetchNextPage } = useInfiniteQuery({
    queryKey: new PageOptions({ filters: raw }).getQueryKey("farmers"),
    queryFn: ({ pageParam }) =>
      getAllFarmers(new PageOptions({ page: pageParam, filters: raw })),
    getNextPageParam: new PageOptions().nextPagefn,
  });

  const { isAdmin, users, pigeons, flights } = useStatistics();

  const onCloseDialog = () => {
    navigate(`/dashboard/farmers?${searchParams.toString()}`);
  };

  const onFarmerItem = (farmer: IFarmer) => {
    navigate(
      `/dashboard/farmers/${farmer.username}?${searchParams.toString()}`
    );
  };

  const onSearchChange = (e: string) => {
    setSearchValue(e);
    debounce.current.exec(e);
  };

  const handleSearchChange = (value: string = "") => {
    setSearchParams({ search: value });
    if (!value) return setFilter();

    const searchFilter = new ChildFilter(FilterName.Username, value);
    setFilter(searchFilter);
  };

  const farmers = data?.pages.flatMap((page) => page.data);
  const lastMeta = data?.pages[data.pages.length - 1].meta;

  useEffect(() => {
    const farmer = farmers?.find((f) => f.username.trim() === username);
    setCurrentFarmer(farmer);
  }, [username, farmers]);

  useEffect(() => {
    if (searchParams.get("search")) {
      setSearchValue(searchParams.get("search") ?? "");
      handleSearchChange(searchParams.get("search") ?? "");
    }
  }, [searchParams.get("search")]);

  const debounce = useRef<Debounce>(new Debounce(handleSearchChange, 500));

  if (!farmers) return null;

  return (
    <>
      <Farmer
        onClose={onCloseDialog}
        onConfirm={() => {}}
        isVisible={true}
        farmer={currentFarmer}
      />
      <VBox position="relative" overflowY="hidden">
        <VBox>
          <Text
            color={theme.palette.Grey90}
            Margin="40px 20px 0 20px"
            type="h3"
            size="large"
            weight="bold"
          >
            {getText("Farmers.View.Title")}
          </Text>
        </VBox>
        <HBox
          Margin="10px 0 0 0"
          Padding="10px 20px"
          backgroundColor={theme.palette.White}
        >
          <TextInput
            width="100%"
            value={searchValue}
            onChange={onSearchChange}
            placeholder="Marek"
          >
            {getText("Farmers.View.SearchBreederInput")}
          </TextInput>
        </HBox>
        <HBox
          visible={isAdmin}
          flexWrap="wrap"
          gap="5px"
          Margin="0 20px 10px 20px"
        >
          <HighligtedText text={`Łącznie: ${users.total}`} />
          <HighligtedText
            visible={isMore}
            backgroundColor={theme.palette.Yellow100}
            text={`Lotowi: ${users.planners}`}
          />
          <HighligtedText
            backgroundColor={theme.palette.Green80}
            text={`Nowi użytkownicy (dzisiaj): ${users.oneDayUsers}`}
          />
          <HighligtedText
            visible={isMore}
            backgroundColor={theme.palette.Green60}
            text={`Nowi użytkownicy (2 dni): ${users.twoDaysUsers}`}
          />
          <HighligtedText
            backgroundColor={theme.palette.Green80}
            text={`Dzisiejsza aktywność: ${users.todayActivity}`}
          />
          <HighligtedText
            visible={isMore}
            backgroundColor={theme.palette.Green60}
            text={`Dwudniowa aktywność: ${users.twoDaysActivity}`}
          />
          <HighligtedText
            visible={isMore}
            backgroundColor={theme.palette.Red100}
            text={`Nigdy niezalogowani: ${users.neverLoggedIn}`}
          />
          <HighligtedText
            visible={isMore}
            text={`Łącznie gołębii: ${pigeons.total}`}
          />
          <HighligtedText
            visible={isMore}
            backgroundColor={theme.palette.Green80}
            text={`Dzisiejsza aktywność (gołębie): ${pigeons.todayActivity}`}
          />
          <HighligtedText
            visible={isMore}
            backgroundColor={theme.palette.Green60}
            text={`Dwudniowa aktywność (gołębie): ${pigeons.twoDaysActivity}`}
          />
          <HighligtedText
            visible={isMore}
            text={`Łącznie loty: ${flights.total}`}
          />
          <HighligtedText
            visible={isMore}
            backgroundColor={theme.palette.Green80}
            text={`Dzisiejsza aktywność (loty): ${flights.todayActivity}`}
          />
          <HighligtedText
            visible={isMore}
            backgroundColor={theme.palette.Green60}
            text={`Dwudniowa aktywność (loty): ${flights.twoDaysActivity}`}
          />
          <ShowMore onClick={() => setIsMore(!isMore)} isMore={isMore} />
        </HBox>
        <VBox overflowY="scroll" id="scrollableFarmerDiv">
          <InfiniteScroll
            dataLength={farmers.length}
            next={fetchNextPage}
            hasMore={lastMeta?.isNext || false}
            loader={
              <HBox justifyContent="center">
                <Loader />
              </HBox>
            }
            scrollableTarget="scrollableFarmerDiv"
          >
            {farmers?.map((farmer, index) => (
              <ListItem
                visible={farmer.type !== UserType.Admin}
                darkest={index % 2 === 0}
                padding="20px"
                key={farmer.id}
                title={farmer.username}
                description={
                  !!farmer.district
                    ? `${RegionDetector.GetRegionName(
                        farmer.district?.country
                      )}, ${farmer.district?.name}`
                    : RegionDetector.GetRegionName(farmer.country)
                }
                icon=""
                onClick={() => onFarmerItem(farmer)}
              >
                <HighligtedText
                  visible={farmer.isFollowed && farmer.isFollowing}
                  backgroundColor={theme.palette.Green60}
                  text={getText("Farmers.View.FriendState")}
                />
                <HighligtedText
                  backgroundColor={theme.palette.Yellow100}
                  visible={farmer.type === UserType.Planner}
                  text={getText("Farmers.View.PlannerState")}
                />
                <HighligtedText
                  visible={farmer.isFollowed && !farmer.isFollowing}
                  backgroundColor={theme.palette.Primary60}
                  text={getText("Farmers.View.InviteFriendState")}
                />
                <HighligtedText
                  visible={!farmer.isFollowed && farmer.isFollowing}
                  backgroundColor={theme.palette.Green60}
                  text={getText("Farmers.View.FriendSendState")}
                />
              </ListItem>
            ))}
          </InfiniteScroll>
        </VBox>
      </VBox>
    </>
  );
};

export default withNavbar(Farmers);
