import { getText } from "../locales/initI18n";
import { Base } from "./Base";
import { EggState } from "./enums/EggStateEnum";
import Pigeon from "./Pigeon";
import PigeonPair from "./PigeonPair";
import User from "./User";

export default class Egg extends Base {
  pigeon!: Pigeon;
  pigeonId!: number;
  pigeonPair = new PigeonPair();
  biologicalPigeonPair = new PigeonPair();
  dateOfLaying!: Date;
  status!: EggState;
  isTransferred!: boolean;
  user = new User();
  brood!: number;

  get isHatched(): boolean {
    return this.status === EggState.Hatched;
  }
  
  get isFertile(): boolean {
    return this.status === EggState.Fertile;
  }

  fillData(data: object): this {
    super.fillData(data);
    if (this.pigeon) this.pigeon = Pigeon.create(this.pigeon);
    this.pigeonPair = PigeonPair.create(this.pigeonPair);
    this.biologicalPigeonPair = PigeonPair.create(this.biologicalPigeonPair);
    this.user = new User().fillData(this.user);
    return this;
  }

  get ReadableStatus(): string {
    return Egg.getTranslatedStatus(this.status);
  }

  static getTranslatedStatus(status: EggState): string {
    switch (status) {
      case EggState.Fertile:
        return getText("Egg.Status.Fertile");
      case EggState.Infertile:
        return getText("Egg.Status.Infertile");
      case EggState.Hatched:
        return getText("Egg.Status.Hatched");
      case EggState.Broken:
        return getText("Egg.Status.Broken");
      case EggState.Dead:
        return getText("Egg.Status.Dead");
      case EggState.Selled:
        return getText("Egg.Status.Selled");
      default:
        return getText("Egg.Status.Unknown");
    }
  }
}
