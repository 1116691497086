import { ITag } from "./Tag";
import { IUser } from "../services/IUserService";
import { PigeonColorEnum } from "./enums/PigeonColorEnum";
import PigeonNoteBase from "./PigeonNoteBase";
import { PigeonSexEnum } from "./enums/PigeonSexEnum";
import { PigeonStateEnum } from "./enums/PigeonStateEnum";
import PigeonAchievement from "./PigeonAchievement";
import PigeonSeasonAssignment from "./PigeonSeasonAssignment";
import Ring from "./Ring";
import Egg from "./Egg";
import { getText } from "../locales/initI18n";

export default class Pigeon extends PigeonNoteBase {
  public ring!: Ring;
  public breed!: string;
  public eyeColor!: string;
  public ownerName!: string;
  public hatchingDate?: Date;
  public year?: number;
  public isActive!: boolean;
  public state!: PigeonStateEnum;
  public sex!: PigeonSexEnum;
  public color!: PigeonColorEnum;
  public createdUser!: IUser;
  public createdUserId!: number;
  public owner!: IUser | null;
  public ownerId!: number;
  public dovecote!: ITag;
  public dovecoteId!: number;
  public father!: Pigeon;
  public fatherId!: number;
  public mother!: Pigeon;
  public motherId!: number;
  public deathDate!: Date;
  public deathCause!: string;
  public motherOf: Pigeon[] = [];
  public fatherOf: Pigeon[] = [];
  public achievements: PigeonAchievement[] = [];
  public seasonAssignments: PigeonSeasonAssignment[] = [];
  public egg!: Egg;

  override fillData(data: any): this {
    super.fillData(data);
    if (!data) return this;

    this.year = this.year ? Number(this.year) : undefined;
    if (typeof data.ring === "string") {
      this.ring = new Ring();
      this.ring.number = data.ring;
    } else this.ring = Ring.create(data.ring);
    if (this.father) {
      this.father = new Pigeon().fillData(this.father);
    }
    if (this.mother) {
      this.mother = new Pigeon().fillData(this.mother);
    }
    if (this.achievements && this.achievements.length > 0) {
      this.achievements = this.achievements.map((achievement: any) =>
        new PigeonAchievement().fillData(achievement)
      );
    }
    if (this.seasonAssignments && this.seasonAssignments.length > 0) {
      this.seasonAssignments = this.seasonAssignments.map((achievement: any) =>
        new PigeonSeasonAssignment().fillData(achievement)
      );
    }
    if (this.motherOf.length > 0) {
      this.motherOf = this.motherOf.map((pigeon: any) =>
        new Pigeon().fillData(pigeon)
      );
    }
    if (this.fatherOf.length > 0) {
      this.fatherOf = this.fatherOf.map((pigeon: any) =>
        new Pigeon().fillData(pigeon)
      );
    }
    if (this.egg) {
      this.egg = Egg.create(this.egg);
    }

    return this;
  }

  isInSeason(season: number): boolean {
    return this.seasonAssignments.some(
      (assignment) => assignment.season.year === season
    );
  }

  update(data: Pigeon) {
    this.name = data.name;
    this.sex = data.sex;
    this.ring = data.ring;
    this.breed = data.breed;
    this.eyeColor = data.eyeColor;
    this.ownerName = data.ownerName;
    this.hatchingDate = data.hatchingDate;
    this.year = data.year;
    this.state = data.state;
    this.color = data.color;
    this.createdUser = data.createdUser;
    this.owner = data.owner;
    this.dovecote = data.dovecote;
    this.father = data.father;
    this.mother = data.mother;
    this.deathDate = data.deathDate;
    this.deathCause = data.deathCause;
    this.motherOf = data.motherOf;
    this.fatherOf = data.fatherOf;

    return this;
  }

  isYoung(): boolean {
    return this.year === new Date().getFullYear();
  }

  isChick(): boolean {
    return !!this.id && !this.RingNumber;
  }

  public removeFather() {
    this.father = undefined as unknown as Pigeon;
    this.fatherId = undefined as unknown as number;
  }

  public removeMother() {
    this.mother = undefined as unknown as Pigeon;
    this.motherId = undefined as unknown as number;
  }

  public get RingNumber(): string {
    return this.ring?.number;
  }

  public get ReadableName(): string {
    return this.name || "-";
  }

  public get fatherOfPigeons(): Pigeon[] {
    return this.fatherOf || [];
  }

  public get motherOfPigeons(): Pigeon[] {
    return this.motherOf || [];
  }

  public get OwnerName(): string {
    if (this.ownerName) {
      return this.ownerName;
    }
    if (this.owner) {
      return this.owner.firstName + " " + this.owner.lastName;
    }
    return this.createdUser.firstName + " " + this.createdUser.lastName;
  }

  public get ReadableSex(): string {
    switch (this.sex) {
      case PigeonSexEnum.Female:
        return getText("General.Female");
      case PigeonSexEnum.Male:
        return getText("General.Male");
      default:
        return getText("General.SexUnknown");
    }
  }

  public get ReadableState(): string {
    switch (this.state) {
      case PigeonStateEnum.Dead:
        return getText("Pigeon.state.Dead");
      // case PigeonStateEnum.Borrowed:
      //   return "Borrowed";
      case PigeonStateEnum.Lost:
        return getText("Pigeon.state.Lost");
      case PigeonStateEnum.Sold:
        return getText("Pigeon.state.Sold");
      case PigeonStateEnum.Unknown:
        return getText("Pigeon.state.Other");
      default:
        return getText("Pigeon.state.Other");
    }
  }

  public get ReadableColor(): string {
    switch (this.color) {
      case PigeonColorEnum.Black:
        return getText("Pigeon.color.Black");
      case PigeonColorEnum.Blue:
        return getText("Pigeon.color.Blue");
      case PigeonColorEnum.Red:
        return getText("Pigeon.color.Red");
      case PigeonColorEnum.White:
        return getText("Pigeon.color.White");
      case PigeonColorEnum.FawnBlack:
        return getText("Pigeon.color.FawnBlack");
      case PigeonColorEnum.FawnRed:
        return getText("Pigeon.color.FawnRed");
      case PigeonColorEnum.Fawn:
        return getText("Pigeon.color.Fawn");
      case PigeonColorEnum.Dark:
        return getText("Pigeon.color.Dark");
      case PigeonColorEnum.Grey:
        return getText("Pigeon.color.Grey");
      case PigeonColorEnum.SpottedBlue:
        return getText("Pigeon.color.SpottedBlue");
      case PigeonColorEnum.SpottedRed:
        return getText("Pigeon.color.SpottedRed");
      case PigeonColorEnum.SpottedDark:
        return getText("Pigeon.color.SpottedDark");
      case PigeonColorEnum.SpottedGrey:
        return getText("Pigeon.color.SpottedGrey");
      case PigeonColorEnum.SpottedVaricolouredBlack:
        return getText("Pigeon.color.SpottedVaricolouredBlack");
      case PigeonColorEnum.SpottedVaricolouredBlue:
        return getText("Pigeon.color.SpottedVaricolouredBlue");
      case PigeonColorEnum.SpottedVaricolouredDark:
        return getText("Pigeon.color.SpottedVaricolouredDark");
      case PigeonColorEnum.SpottedVaricolouredFawn:
        return getText("Pigeon.color.SpottedVaricolouredFawn");
      case PigeonColorEnum.SpottedVaricolouredRed:
        return getText("Pigeon.color.SpottedVaricolouredRed");
      case PigeonColorEnum.VaricolouredBlack:
        return getText("Pigeon.color.VaricolouredBlack");
      case PigeonColorEnum.VaricolouredBlue:
        return getText("Pigeon.color.VaricolouredBlue");
      case PigeonColorEnum.VaricolouredDark:
        return getText("Pigeon.color.VaricolouredDark");
      case PigeonColorEnum.VaricolouredFawn:
        return getText("Pigeon.color.VaricolouredFawn");
      case PigeonColorEnum.VaricolouredRed:
        return getText("Pigeon.color.VaricolouredRed");
      case PigeonColorEnum.VaricolouredFawnBlack:
        return getText("Pigeon.color.VaricolouredFawnBlack");
      case PigeonColorEnum.VaricolouredFawnRed:
        return getText("Pigeon.color.VaricolouredFawnRed");
      case PigeonColorEnum.VaricolouredGrey:
        return getText("Pigeon.color.VaricolouredGrey");
      default:
        return getText("Pigeon.color.Unknown");
    }
  }
  prepareForSave(): any {
    this.year = !this.year ? undefined : this.year;
    this.hatchingDate = !this.hatchingDate
      ? undefined
      : new Date(this.hatchingDate);
  }

  toJSON() {
    return {
      ...super.toJSON(),
      ring: this?.ring?.number,
      fatherId: this.fatherId || this.father?.id,
      motherId: this.motherId || this.mother?.id,
    };
  }
}
