import IStyledText from "../../styles/ITypographyStyled";
import { StyledText } from "../../styles/Typography.styled";
import TextWeightEnum from "../../models/enums/TextWeightEnum";

/**
 * @description Text component for typography styling you can set the font size, font weight, font family, color, and text align for different text heading levels, paragraphs, and links.
 * @param props
 * @param props.children text to be displayed
 * @param props.type type of text. Choosen value of type make different value on weight and size. Possible values are:
 * - **h1**: heading 1
 * - **h2**: heading 2
 * - **h3**: heading 3
 * - **h4**: heading 4
 * - **h5**: heading 5
 * - **h6**: heading 6
 * - **body**: body
 * - **caption**: caption
 * - **footer**: footer
 * @param props.weight weight of text e.g. bold, medium, regular
 * - if type eq **h1**, **h2**, **h3**, **h4**, **h5**, **h6**: `bold`, `medium`
 * - if type eq **body**: `bold`, `medium`, `regular`
 * - if type eq **caption**: `medium`, `regular`
 * - if type eq **footer**: `bold`, `medium`, `regular`
 * @param props.size size of text e.g. large, medium, small
 * - if type eq **h1**, **h2**, **h3**, **h4**, **h5**, **h6**: `large`, `small`
 * - if type eq **body**: `large`, `medium`, `small`
 * - if type eq **caption**: `large`, `medium`, `small`
 * - if type eq **footer**: `medium`
 * @param [props.Margin] margin for the text e.g. 10px 0 0 0
 * @param [props.Padding] padding for the text e.g. 10px 0 0 0
 * @param [props.width] width for the text
 * @param [props.isCapitalize] if true, text will be capitalized the first letter like a sentence
 * @param [props.textTransform] text transform for the text e.g. uppercase, lowercase, capitalize
 * @param [props.textAlign] text align for the text e.g. left, center, right
 * @param [props.textDecoration] text decoration for the text e.g. underline, line-through
 * @param [props.color] color for the text e.g. Grey60, Red80, White (You can see the color list in the /constants/Colors.tsx file)
 * @param [props.visible] if true, text will be visible
 * @param [props.zIndex] z-index for the text
 * @param [props.fontStyle] font style for the text e.g. italic
 * @example
 * // Example of using Text component
 * // Text in heading:
 *  <Text
 *    as="h2"
 *    type="h3"
 *    size="large"
 *    weight="medium"
 *    margin="50px 0 10px 0">
 *     Title of the page
 *   </Text>
 * // Text in body:
 *  <Text
 *    type="body"
 *    size="medium"
 *    weight="medium"
 *    color={theme.palette.Grey70}>
 *   Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
 *  </Text>
 * // Text in caption:
 *  <Text
 *    type="caption"
 *    size="large"
 *    weight="medium"
 *    color={theme.palette.Grey60}>
 *      Small description
 *  </Text>
 * // Above code will produce text to use in the application
 * // You can create other text styles by changing the value of the propsx
 * @returns
 */
const Text = (props: IStyledText) => {
  return <StyledText {...props}>{props.children}</StyledText>;
};

export const Bold = (props: IStyledText) => {
  return (
    <StyledText {...props} weight={TextWeightEnum.bold}>
      {props.children}
    </StyledText>
  );
};

export const Medium = (props: IStyledText) => {
  return (
    <StyledText {...props} weight={TextWeightEnum.medium}>
      {props.children}
    </StyledText>
  );
};

export const Regular = (props: IStyledText) => {
  return (
    <StyledText {...props} weight={TextWeightEnum.regular}>
      {props.children}
    </StyledText>
  );
};

export default Text;
