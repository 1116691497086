import { Delete, authPost, post } from "./BaseService";
import { LoginData, LoginProps, RegisterFormProps } from "./IAuthService";

export const signIn = (data: LoginProps) => {
  return post<LoginData>("auth/login", data);
};

export const googleSignIn = (data: { token: string; country: string }) => {
  return post<LoginData>("auth/google-login", data);
};

export const signUp = (data: RegisterFormProps) => {
  if (!data.phoneNumber) data.phoneNumber = undefined;
  data.district =
    typeof data.district === "number" ? data.district : data.district?.id;
  data.branch = typeof data.branch === "number" ? data.branch : data.branch?.id;
  data.section =
    typeof data.section === "number" ? data.section : data.section?.id;
  return post("auth/register", data);
};

export const changePassword = (oldPassword: string, newPassword: string) => {
  return authPost("auth/password/change", { oldPassword, newPassword });
};

export const rememberPassword = (email: string) => {
  return post("auth/password/requestReset", { email });
};

export const requestDeleteAccount = (email: string) => {
  return post("auth/delete-account/request", { email });
};

export const deleteAccount = (token: string) => {
  return Delete(`auth/delete-account/${token}`);
};
