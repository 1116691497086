import { getUserData } from "../services/UserService";
import { useQuery } from "@tanstack/react-query";

const useUser = () => {
  const query = useQuery({
    queryKey: ["user"],
    queryFn: getUserData,
    retry: 0,
  });

  return query;
};

export default useUser;
