import {
  Button,
  MessageBoxAction,
  ObjectStatus,
  Table,
  TableCell,
  TableGrowing,
  TableHeaderCell,
  TableHeaderRow,
  TableRow,
} from "@ui5/webcomponents-react";

import ButtonDesign from "@ui5/webcomponents/dist/types/ButtonDesign";
import PageOptions from "../models/PageOptions";
import PigeonPair from "../models/PigeonPair";
import { getText } from "../locales/initI18n";
import { removePair } from "../services/PigeonPairService";
import { setError } from "../redux/modules/error";
import { useAppDispatch } from "../redux/hooks";
import useMessage from "../hooks/useMessage";
import { useNavigate } from "react-router-dom";
import usePigeonPairs from "../hooks/usePigeonPairs";

export interface PigeonSeasonPairTableProps {
  choosenSeason?: null | number;
  interactiveRow?: boolean;
  onPairPress?: (pair: PigeonPair) => void;
  searchValue?: string;
}

const PigeonSeasonPairTable = ({
  choosenSeason,
  interactiveRow,
  onPairPress,
  searchValue = "",
}: PigeonSeasonPairTableProps) => {
  const navigate = useNavigate();
  const { openMessage } = useMessage();
  const { invalidate, pigeonPairs, lastMeta, fetchNextPage, busy } =
    usePigeonPairs(
      new PageOptions({
        filters: `(male.ring.number=='=LIKE=${searchValue}=LIKE=' && season.year==${choosenSeason}) || (female.ring.number=='=LIKE=${searchValue}=LIKE=' && season.year==${choosenSeason})`,
      })
    );
  const dispatch = useAppDispatch();

  const onDelete = async (pair: PigeonPair) => {
    const confirm = await openMessage(
      `Czy na pewno chcesz usunąć parę: ${pair.maleRingNumber} - ${pair.femaleRingNumber}? Para zostanie usunięta natychmiast po zaakceptowaniu.`
    );
    if (confirm !== MessageBoxAction.OK) return;

    try {
      await removePair(pair.id);
      invalidate();
    } catch (e) {
      dispatch(setError(e));
    }
  };

  const navigateToPigeon = (id: number) => {
    navigate(`/dashboard/pigeons/${choosenSeason}/${id}`);
  };

  return (
    <Table
      overflowMode="Popin"
      loading={busy}
      headerRow={[
        <TableHeaderRow>
          <TableHeaderCell minWidth="200px">
            {getText("General.Male")}
          </TableHeaderCell>
          <TableHeaderCell minWidth="200px">
            {getText("General.Female")}
          </TableHeaderCell>
          <TableHeaderCell width="60px"></TableHeaderCell>
        </TableHeaderRow>,
      ]}
      onRowClick={(event) => {
        const key = event.detail.row.rowKey;
        onPairPress?.(pigeonPairs.find((pair) => pair.id === parseInt(key))!);
      }}
    >
      {lastMeta?.isNext && (
        <TableGrowing
          type="Button"
          growing-text={getText("General.More")}
          slot="features"
          onLoadMore={() => fetchNextPage()}
        ></TableGrowing>
      )}
      {pigeonPairs?.map((pair) => (
        <TableRow
          interactive={interactiveRow}
          rowKey={pair.id.toString()}
          id={pair.id.toString()}
          key={`PigeonSeasonPairTable-${pair.id}`}
        >
          <TableCell>
            <ObjectStatus
              onClick={() => navigateToPigeon(pair.male.id)}
              interactive
              inverted
            >
              {pair.maleRingNumber}
            </ObjectStatus>
          </TableCell>
          <TableCell>
            <ObjectStatus
              onClick={() => navigateToPigeon(pair.female.id)}
              interactive
              inverted
            >
              {pair.femaleRingNumber}
            </ObjectStatus>
          </TableCell>
          <TableCell>
            <Button
              onClick={() => onDelete(pair)}
              icon="delete"
              design={ButtonDesign.Negative}
            />
          </TableCell>
        </TableRow>
      ))}
    </Table>
  );
};

export default PigeonSeasonPairTable;
