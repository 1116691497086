import { Button, ButtonType } from "../../styles/Button.styled";
import { HBox, VBox } from "../../styles/Flexbox.styled";

import HighligtedText from "../HighlightedText/HighlightedText";
import Text from "../Text/Text";
import TextSizeEnum from "../../models/enums/TextSizeEnum";
import TextTypeEnum from "../../models/enums/TextTypeEnum";
import TextWeightEnum from "../../models/enums/TextWeightEnum";
import { getText } from "../../locales/initI18n";
import { useTheme } from "styled-components";

const ThirdNews = () => {
  const theme = useTheme();
  const goTo = (url: string) => {
    window.open(url, "_blank");
  };

  return (
    <VBox width="100%">
      <HBox justifyContent="flex-end">
        <HighligtedText
          text={getText("ThirdNews.PublicationDate")}
          backgroundColor={theme.palette.Yellow100}
        />
      </HBox>
      <VBox gap="20px">
        <VBox gap="10px">
          <Text
            type={TextTypeEnum.h6}
            size={TextSizeEnum.large}
            weight={TextWeightEnum.medium}
            color={theme.palette.Grey90}
          >
            {getText("ThirdNews.PigeonNotesTitle")}
          </Text>
          <Text
            type={TextTypeEnum.body}
            size={TextSizeEnum.medium}
            weight={TextWeightEnum.regular}
            color={theme.palette.Grey70}
            textAlign="justify"
          >
            {getText("ThirdNews.PigeonNotesDescription1")}
          </Text>
          <VBox
            justifyContent="center"
            alignItems="center"
            Padding="5px"
            BorderRadius="5px"
            backgroundColor={theme.palette.Grey10}
          >
            <img
              width="90%"
              src="/assets/News/ThirdNewsPigeonNotes1.png"
              alt=""
            />
            <Text
              textAlign="center"
              type={TextTypeEnum.caption}
              size={TextSizeEnum.small}
              weight={TextWeightEnum.regular}
              color={theme.palette.Grey50}
            >
              {getText("ThirdNews.PigeonNotesDescription1Caption")}
            </Text>
          </VBox>
          <Text
            type={TextTypeEnum.body}
            size={TextSizeEnum.medium}
            weight={TextWeightEnum.regular}
            color={theme.palette.Grey70}
            textAlign="justify"
          >
            {getText("ThirdNews.PigeonNotesDescription2")}
          </Text>
          <VBox
            justifyContent="center"
            alignItems="center"
            Padding="5px"
            BorderRadius="5px"
            backgroundColor={theme.palette.Grey10}
          >
            <img
              width="90%"
              src="/assets/News/ThirdNewsPigeonNotes2.png"
              alt=""
            />
            <Text
              textAlign="center"
              type={TextTypeEnum.caption}
              size={TextSizeEnum.small}
              weight={TextWeightEnum.regular}
              color={theme.palette.Grey50}
            >
              {getText("ThirdNews.PigeonNotesDescription2Caption")}
            </Text>
          </VBox>
          <Text
            type={TextTypeEnum.body}
            size={TextSizeEnum.medium}
            weight={TextWeightEnum.regular}
            color={theme.palette.Grey70}
            textAlign="justify"
          >
            {getText("ThirdNews.PigeonNotesDescription3")}
          </Text>
          <VBox
            justifyContent="center"
            alignItems="center"
            Padding="5px"
            BorderRadius="5px"
            backgroundColor={theme.palette.Grey10}
          >
            <img
              width="90%"
              src="/assets/News/ThirdNewsPigeonNotes3.png"
              alt=""
            />
            <Text
              textAlign="center"
              type={TextTypeEnum.caption}
              size={TextSizeEnum.small}
              weight={TextWeightEnum.regular}
              color={theme.palette.Grey50}
            >
              {getText("ThirdNews.PigeonNotesDescription3Caption")}
            </Text>
          </VBox>
          <Text
            type={TextTypeEnum.body}
            size={TextSizeEnum.medium}
            weight={TextWeightEnum.regular}
            color={theme.palette.Grey70}
            textAlign="justify"
          >
            {getText("ThirdNews.PigeonNotesDescription4")}
          </Text>
          <VBox
            justifyContent="center"
            alignItems="center"
            Padding="5px"
            BorderRadius="5px"
            backgroundColor={theme.palette.Grey10}
          >
            <img
              width="90%"
              src="/assets/News/ThirdNewsPigeonNotes4.png"
              alt=""
            />
            <Text
              textAlign="center"
              type={TextTypeEnum.caption}
              size={TextSizeEnum.small}
              weight={TextWeightEnum.regular}
              color={theme.palette.Grey50}
            >
              {getText("ThirdNews.PigeonNotesDescription4Caption")}
            </Text>
          </VBox>
        </VBox>
        <VBox gap="10px">
          <Text
            type={TextTypeEnum.h6}
            size={TextSizeEnum.large}
            weight={TextWeightEnum.medium}
            color={theme.palette.Grey90}
          >
            {getText("ThirdNews.OpinionTitle")}
          </Text>
          <Text
            type={TextTypeEnum.body}
            size={TextSizeEnum.medium}
            weight={TextWeightEnum.regular}
            color={theme.palette.Grey70}
            textAlign="justify"
          >
            {getText("ThirdNews.OpinionDescription")}
          </Text>
        </VBox>
        <VBox gap="10px">
          <Text
            type={TextTypeEnum.h6}
            size={TextSizeEnum.large}
            weight={TextWeightEnum.medium}
            color={theme.palette.Grey90}
          >
            {getText("ThirdNews.ChangesFlightTitle")}
          </Text>
          <Text
            type={TextTypeEnum.body}
            size={TextSizeEnum.medium}
            weight={TextWeightEnum.regular}
            color={theme.palette.Grey70}
            textAlign="justify"
          >
            {getText("ThirdNews.ChangesFlightDescription")}
          </Text>
          <VBox
            justifyContent="center"
            alignItems="center"
            Padding="5px"
            BorderRadius="5px"
            backgroundColor={theme.palette.Grey10}
          >
            <img
              width="90%"
              src="/assets/News/ThirdNewsChangesFlight1.png"
              alt="Changes on flight page"
            />
            <Text
              textAlign="center"
              type={TextTypeEnum.caption}
              size={TextSizeEnum.small}
              weight={TextWeightEnum.regular}
              color={theme.palette.Grey50}
            >
              {getText("ThirdNews.ChangesFlightCaption")}
            </Text>
          </VBox>
        </VBox>
        <VBox gap="10px">
          <Text
            type={TextTypeEnum.h6}
            size={TextSizeEnum.large}
            weight={TextWeightEnum.medium}
            color={theme.palette.Grey90}
          >
            {getText("ThirdNews.NewestVideosTitle")}
          </Text>
          <Text
            type={TextTypeEnum.body}
            size={TextSizeEnum.medium}
            weight={TextWeightEnum.regular}
            color={theme.palette.Grey70}
            textAlign="justify"
          >
            {getText("ThirdNews.NewestVideosDescription")}
          </Text>
          <HBox justifyContent="center">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/kNsTzDUySmE?si=77iRi9I3U8rTK4Mf"
              style={{ zoom: 0.5 }}
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen={true}
            ></iframe>
          </HBox>
          <HBox justifyContent="center">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/6cH2mQ6MXWw?si=RpYETvGpSDSO_Lbu"
              title="YouTube video player"
              style={{ zoom: 0.5 }}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen={true}
            ></iframe>
          </HBox>
          <HBox justifyContent="center">
            <iframe
              width="560"
              height="315"
              style={{ zoom: 0.5 }}
              src="https://www.youtube.com/embed/uy8Yk4j6gyE?si=iTDrXL_PHQmMbdfb"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen={true}
            ></iframe>
          </HBox>
        </VBox>
        <VBox gap="10px">
          <Text
            type={TextTypeEnum.h6}
            size={TextSizeEnum.large}
            weight={TextWeightEnum.medium}
            color={theme.palette.Grey90}
          >
            {getText("ThirdNews.VitaleSprintTitle") + " "}
            <Text weight={TextWeightEnum.bold}> Vitale Sprint</Text>
          </Text>
          <Text
            type={TextTypeEnum.body}
            size={TextSizeEnum.medium}
            weight={TextWeightEnum.regular}
            color={theme.palette.Grey70}
            textAlign="justify"
          >
            {getText("ThirdNews.VitaleSprintDescription")}
          </Text>
          <VBox
            justifyContent="center"
            alignItems="center"
            Padding="5px"
            BorderRadius="5px"
            backgroundColor={theme.palette.Grey10}
          >
            <img
              width="90%"
              src="/assets/News/ThirdNewsVitaleSprint.png"
              alt="Vitale Sprint"
            />
            <Text
              textAlign="center"
              type={TextTypeEnum.caption}
              size={TextSizeEnum.small}
              weight={TextWeightEnum.regular}
              color={theme.palette.Grey50}
            >
              Vitale Sprint
            </Text>
          </VBox>
          <Button
            buttonType={ButtonType.Primary}
            onClick={() => goTo("https://digging.pl/produkt/vita-sprint/")}
          >
            {getText("ThirdNews.VitaleSprintButton")}
          </Button>
        </VBox>
        <VBox gap="10px">
          <Text
            type={TextTypeEnum.h6}
            size={TextSizeEnum.large}
            weight={TextWeightEnum.medium}
            color={theme.palette.Grey90}
          >
            {getText("ThirdNews.FixTitle")}
          </Text>
          <Text
            type={TextTypeEnum.body}
            size={TextSizeEnum.medium}
            weight={TextWeightEnum.regular}
            color={theme.palette.Grey70}
            textAlign="justify"
          >
            {getText("ThirdNews.FixDescription")}
          </Text>
          <ul>
            <li>
              <Text
                type={TextTypeEnum.body}
                size={TextSizeEnum.medium}
                weight={TextWeightEnum.regular}
                color={theme.palette.Grey70}
                textAlign="justify"
              >
                {getText("ThirdNews.Fix1")}
              </Text>
            </li>
            <li>
              <Text
                type={TextTypeEnum.body}
                size={TextSizeEnum.medium}
                weight={TextWeightEnum.regular}
                color={theme.palette.Grey70}
                textAlign="justify"
              >
                {getText("ThirdNews.Fix2")}
              </Text>
            </li>
            <li>
              <Text
                type={TextTypeEnum.body}
                size={TextSizeEnum.medium}
                weight={TextWeightEnum.regular}
                color={theme.palette.Grey70}
                textAlign="justify"
              >
                {getText("ThirdNews.Fix3")}
              </Text>
            </li>
          </ul>
        </VBox>
      </VBox>
    </VBox>
  );
};

export default ThirdNews;
