import { HBox, ResponsiveVBox, VBox } from "../styles/Flexbox.styled";

import Text from "../components/Text/Text";
import TextSizeEnum from "../models/enums/TextSizeEnum";
import TextTypeEnum from "../models/enums/TextTypeEnum";
import TextWeightEnum from "../models/enums/TextWeightEnum";
import { getText } from "../locales/initI18n";
import { useTheme } from "styled-components";

const PolicyPrivacy = () => {
  const theme = useTheme();
  return (
    <VBox
      width="100%"
      justifyContent="center"
      alignItems="center"
      backgroundColor={theme.palette.White}
      Padding="10px"
      overflowY="scroll"
    >
      <VBox Margin="0 0 40px 0">
        <Text
          color={theme.palette.Grey90}
          size={TextSizeEnum.large}
          weight={TextWeightEnum.bold}
          type={TextTypeEnum.h1}
          Margin="0 0 30px 0"
        >
          Pigeon Map
        </Text>
        <Text
          color={theme.palette.Grey90}
          size={TextSizeEnum.large}
          weight={TextWeightEnum.bold}
          type={TextTypeEnum.h3}
          Margin="0 0 10px 0"
        >
          {getText("policyPrivacy.Title")}
        </Text>
        <Text
          color={theme.palette.Grey80}
          type={TextTypeEnum.body}
          size={TextSizeEnum.medium}
          weight={TextWeightEnum.regular}
        >
          {getText("policyPrivacy.Administration")}
        </Text>
      </VBox>
      <VBox Margin="0 0 40px 0">
        <Text
          color={theme.palette.Grey90}
          size={TextSizeEnum.large}
          weight={TextWeightEnum.bold}
          type={TextTypeEnum.h4}
          Margin="0 0 10px 0"
        >
          {`1. ${getText("policyPrivacy.CollectedDataTitle")}`}
        </Text>
        <HBox>
          <VBox
            Margin="0 0 0 5px"
            maxWidth="15px"
            minWidth="15px"
            backgroundColor={theme.palette.Grey10}
          />
          <Text
            color={theme.palette.Grey80}
            type={TextTypeEnum.body}
            size={TextSizeEnum.medium}
            weight={TextWeightEnum.regular}
            Margin="0 0 0 5px"
          >
            {getText("policyPrivacy.CollectedDataDescription")}
          </Text>
        </HBox>
      </VBox>
      <VBox Margin="0 0 40px 0">
        <Text
          color={theme.palette.Grey90}
          size={TextSizeEnum.large}
          weight={TextWeightEnum.bold}
          type={TextTypeEnum.h4}
          Margin="0 0 10px 0"
        >
          {`2. ${getText("policyPrivacy.DataShareTitle")}`}
        </Text>
        <HBox>
          <VBox
            Margin="0 0 0 5px"
            maxWidth="15px"
            minWidth="15px"
            backgroundColor={theme.palette.Grey10}
          />
          <Text
            color={theme.palette.Grey80}
            type={TextTypeEnum.body}
            size={TextSizeEnum.medium}
            weight={TextWeightEnum.regular}
            Margin="0 0 0 5px"
          >
            {getText("policyPrivacy.DataShareDescription")}
          </Text>
        </HBox>
      </VBox>
      <VBox Margin="0 0 40px 0">
        <Text
          color={theme.palette.Grey90}
          size={TextSizeEnum.large}
          weight={TextWeightEnum.bold}
          type={TextTypeEnum.h4}
          Margin="0 0 10px 0"
        >
          {`3. ${getText("policyPrivacy.ConsentToSharingDataTitle")}`}
        </Text>
        <HBox>
          <VBox
            Margin="0 0 0 5px"
            maxWidth="15px"
            minWidth="15px"
            backgroundColor={theme.palette.Grey10}
          />
          <Text
            color={theme.palette.Grey80}
            type={TextTypeEnum.body}
            size={TextSizeEnum.medium}
            weight={TextWeightEnum.regular}
            Margin="0 0 0 5px"
          >
            {getText("policyPrivacy.ConsentToSharingDataDescription")}
          </Text>
        </HBox>
      </VBox>
      <VBox Margin="0 0 40px 0">
        <Text
          color={theme.palette.Grey90}
          size={TextSizeEnum.large}
          weight={TextWeightEnum.bold}
          type={TextTypeEnum.h4}
          Margin="0 0 10px 0"
        >
          {`4. ${getText("policyPrivacy.DataRemovalTitle")}`}
        </Text>
        <HBox>
          <VBox
            Margin="0 0 0 5px"
            maxWidth="15px"
            minWidth="15px"
            backgroundColor={theme.palette.Grey10}
          />
          <Text
            color={theme.palette.Grey80}
            type={TextTypeEnum.body}
            size={TextSizeEnum.medium}
            weight={TextWeightEnum.regular}
            Margin="0 0 0 5px"
          >
            {getText("policyPrivacy.DataRemovalDescription")}
          </Text>
        </HBox>
      </VBox>
      <VBox Margin="0 0 40px 0">
        <Text
          color={theme.palette.Grey90}
          size={TextSizeEnum.large}
          weight={TextWeightEnum.bold}
          type={TextTypeEnum.h4}
          Margin="0 0 10px 0"
        >
          {`5. ${getText("policyPrivacy.PurposeOfDataCollectionTitle")}`}
        </Text>
        <HBox>
          <VBox
            Margin="0 0 0 5px"
            maxWidth="15px"
            minWidth="15px"
            backgroundColor={theme.palette.Grey10}
          />
          <Text
            color={theme.palette.Grey80}
            type={TextTypeEnum.body}
            size={TextSizeEnum.medium}
            weight={TextWeightEnum.regular}
            Margin="0 0 0 5px"
          >
            {getText("policyPrivacy.PurposeOfDataCollectionDescription")}
          </Text>
        </HBox>
      </VBox>
      <VBox Margin="0 0 40px 0">
        <Text
          color={theme.palette.Grey90}
          size={TextSizeEnum.large}
          weight={TextWeightEnum.bold}
          type={TextTypeEnum.h4}
          Margin="0 0 10px 0"
        >
          {`6. ${getText("policyPrivacy.DataSafetyTitle")}`}
        </Text>
        <HBox>
          <VBox
            Margin="0 0 0 5px"
            maxWidth="15px"
            minWidth="15px"
            backgroundColor={theme.palette.Grey10}
          />
          <Text
            color={theme.palette.Grey80}
            type={TextTypeEnum.body}
            size={TextSizeEnum.medium}
            weight={TextWeightEnum.regular}
            Margin="0 0 0 5px"
          >
            {getText("policyPrivacy.DataSafetyDescription")}
          </Text>
        </HBox>
      </VBox>
      <VBox Margin="0 0 40px 0">
        <Text
          color={theme.palette.Grey90}
          size={TextSizeEnum.large}
          weight={TextWeightEnum.bold}
          type={TextTypeEnum.h4}
          Margin="0 0 10px 0"
        >
          {`7. ${getText(
            "policyPrivacy.RightToAccessCorrectAndDeleteDataTitle"
          )}`}
        </Text>
        <HBox>
          <VBox
            Margin="0 0 0 5px"
            maxWidth="15px"
            minWidth="15px"
            backgroundColor={theme.palette.Grey10}
          />
          <Text
            color={theme.palette.Grey80}
            type={TextTypeEnum.body}
            size={TextSizeEnum.medium}
            weight={TextWeightEnum.regular}
            Margin="0 0 0 10px"
          >
            {getText(
              "policyPrivacy.RightToAccessCorrectAndDeleteDataDescription"
            )}
          </Text>
        </HBox>
      </VBox>
      <VBox Margin="0 0 40px 0">
        <Text
          color={theme.palette.Grey90}
          size={TextSizeEnum.large}
          weight={TextWeightEnum.bold}
          type={TextTypeEnum.h4}
          Margin="0 0 10px 0"
        >
          {`8. ${getText("policyPrivacy.LegalBasisTitle")}`}
        </Text>
        <HBox>
          <VBox
            Margin="0 0 0 5px"
            maxWidth="15px"
            minWidth="15px"
            backgroundColor={theme.palette.Grey10}
          />
          <Text
            color={theme.palette.Grey80}
            type={TextTypeEnum.body}
            size={TextSizeEnum.medium}
            weight={TextWeightEnum.regular}
            Margin="0 0 0 10px"
          >
            {getText("policyPrivacy.LegalBasisDescription")}
          </Text>
        </HBox>
      </VBox>
      <VBox Margin="0 0 40px 0">
        <Text
          color={theme.palette.Grey80}
          type={TextTypeEnum.body}
          size={TextSizeEnum.medium}
          weight={TextWeightEnum.regular}
        >
          {getText("policyPrivacy.TargetDescription")}
        </Text>
      </VBox>
    </VBox>
  );
};

export default PolicyPrivacy;
