import Formatter from "../utils/Formatter";
import Season from "../models/Season";
import { authGet } from "./BaseService";

export const getSeasons = async () => {
  const result = await authGet<object[]>(
    `pigeon-season-assignment/seasons/${Formatter.Year(new Date())}`
  );
  return result.data.map((item) => Season.create(item));
};
