import ChildFilter from "../../../models/Filter/ChildFilter";
import { ChooseMotherProps } from "./IChooseMother";
import ChoosePigeon from "./ChoosePigeon";
import { FilterName } from "../../../models/enums/FilterName";
import ParentFilter from "../../../models/Filter/ParentFilter";
import { ParentFilterOperator } from "../../../models/Filter/ParentFilterOperator";

const ChooseMother = (props: ChooseMotherProps) => {
  return (
    <ChoosePigeon
      isVisible={props.isVisible}
      onConfirm={props.onConfirm}
      onClose={props.onClose}
      title="Choose mother"
      description="Choose a mother for the pigeon"
      initFilter={
        new ParentFilter(
          [
            new ChildFilter(FilterName.Sex, 1),
            new ChildFilter(FilterName.NotId, props.pigeon.id),
          ],
          ParentFilterOperator.And
        )
      }
    />
  );
};
export default ChooseMother;
