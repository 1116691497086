import { ErrorState } from "./state";
import { PayloadAction } from "@reduxjs/toolkit";

const setError = (state: ErrorState, action: PayloadAction<unknown>) => {
  state.error = action.payload;
};

const reducers = {
  setError,
};

export default reducers;
